//Vendors
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import { logoPdf } from 'config/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const styles = StyleSheet.create({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    height: 'auto',
    marginBottom: 40,
  },
  titleHead: {
    color: '#1e1e1e',
    fontSize: 20,
    marginBottom: 7,
    fontWeight: 'normal',
  },
  titleHeadBold: {
    color: '#1e1e1e',
    fontSize: 20,
    marginBottom: 7,
    fontWeight: 'bold',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  brandInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: '#F4F4F4',
    padding: '9px 17px 8px 65px',
  },
})

interface HeadPDFProps {
  readonly title: string
  readonly subtitle: string
  readonly fullName?: string
}

export const HeadPDF: React.FC<HeadPDFProps> = ({ title, subtitle, fullName = '' }) => {
  const { full_name, } = useSelector(
    (state: RootState) => state.authReducer.user
  )

  return (
    <View style={styles.head} wrap>
      <Image
        src={logoPdf}
        style={{ width: '250px', height: '96px' }}
      />
      <View style={styles.brandInfo} wrap>
        <Text style={styles.titleHeadBold}>{title}</Text>
        <Text style={styles.titleHead}>{full_name || fullName}</Text>
        <Text
          style={{
            ...styles.titleHeadBold,
            ...styles.textUppercase,
          }}
        >
          {subtitle}
        </Text>
      </View>
    </View>
  )
}
