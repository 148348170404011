//Vendors
import { Icon, IconPaths } from '@octano/global-ui'

interface DropBtnProps {
  readonly iconName?: keyof typeof IconPaths
  readonly text: string
  readonly onClick: () => void
}

export const DropBtn: React.FC<DropBtnProps> = ({
  iconName,
  text,
  onClick,
}) => {
  return (
    <div className="drop-btn" onClick={onClick}>
      {iconName && <Icon name={iconName} size="14px" color="inherit"></Icon>}
      <span className="drop-btn-text">{text}</span>
    </div>
  )
}
