// Vendors
import { useEffect } from 'react'
import { Button, Table } from '@octano/global-ui'
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

//Hooks
import { useModal, useTableData } from 'hooks'
import { usePagination } from 'hooks/usePagination'

//Components
import { CardLayout } from 'components/CardLayout'
import { AddNewRequestsModal } from './parts/AddNewRequestsModal'
import { CellFormat } from 'components/containers/CellFormat'
import { ErrorTableData } from 'components/ErrorTableData'

// Store
import { RootState } from 'store'
import { getRequests } from 'reducers/ordsSlice'

const columnsDesktop: ColumnTable[] = [
  {
    columnName: 'nro_resolucion',
    headerText: <CellFormat>Nº Solicitud</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'fecha_resolucion',
    headerText: <CellFormat>Fecha</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format('DD/MM/YYYY')}</CellFormat>
    ),
  },
  {
    columnName: 'Hour',
    headerText: <CellFormat>Hora</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'nmbclase',
    headerText: <CellFormat>Clase </CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'nombre',
    headerText: <CellFormat>Nombre solicitante </CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'cargo',
    headerText: <CellFormat>Cargo solicitante</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'motivo',
    headerText: <CellFormat>Motivo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
]

const columnsTable: ColumnTable[] = [
  {
    columnName: 'nro_resolucion',
    headerText: <CellFormat>Nº Solicitud</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'fecha_ini',
    headerText: <CellFormat>Fecha</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format('DD/MM/YYYY')}</CellFormat>
    ),
  },

  {
    columnName: 'nmbclase',
    headerText: <CellFormat>Clase </CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },

  {
    columnName: 'cargo',
    headerText: <CellFormat>Cargo solicitante</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'motivo',
    headerText: <CellFormat>Motivo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
]

const columnsMobile: ColumnTable[] = [
  {
    columnName: 'nro_resolucion',
    headerText: <CellFormat>Nº Solicitud</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'nmbclase',
    headerText: <CellFormat>Clase</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'motivo',
    headerText: <CellFormat>Motivo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
]

const Requests: React.FC = () => {
  const dispatch = useDispatch()
  const { loading: isLoading, requests } = useSelector(
    (state: RootState) => state.ordsReducer
  )

  const pagination = usePagination({ totalItems: requests.count })

  const { columns } = useTableData({
    columnsMobile,
    columnsDesktop,
    columnsTable,
  })

  const { modalOpen } = useModal()

  useEffect(() => {
    dispatch(
      getRequests({
        offset: pagination.offset,
        limit: pagination.itemsPerPage,
      })
    )
  }, [dispatch, pagination.offset, pagination.itemsPerPage])

  return (
    <section id="solicitudes ">
      <CardLayout>
        <Button
          text="+ INGRESAR NUEVA SOLICITUD"
          color="primary"
          onClick={() => modalOpen(<AddNewRequestsModal />)}
          className="mb-4 font-weight-bold"
          size="md"
        />
        <div className="w-100">
          <Table
            columns={columns}
            data={requests.data}
            noResultsText={<ErrorTableData />}
            striped={false}
            isLoadingResults={isLoading}
            pagination={{
              currentPage: pagination.currentPage,
              itemsPerPage: pagination.itemsPerPage,
              onChangePage: pagination.onChangePage,
              totalItems: pagination.totalItems,
              totalPages: pagination.totalPages,
            }}
          />
        </div>
      </CardLayout>
    </section>
  )
}

export default Requests
