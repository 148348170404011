// Vendors
import { View, Text } from '@react-pdf/renderer'

interface TitleSectionProps {
  title: string
  fullWidth?: boolean
  styles?: {}
}
//Componente Visual que retorna los items
//con los estilos necesarios para los items de info del PDF
export const TitleSection: React.FC<TitleSectionProps> = ({
  children,
  title = '',
  fullWidth = false,
  styles,
}) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: `${fullWidth ? '100%' : 'auto'}`,
      ...styles,
    }}
  >
    <Text
      style={{
        color: '#1E1E1E',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      }}
    >
      {title}
      {children ? ':' : ''}
    </Text>
    <Text
      style={{
        fontWeight: 'normal',
        color: '#1E1E1E',
        textTransform: 'uppercase',
      }}
    >
      {children}
    </Text>
  </View>
)
