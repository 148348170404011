// Vendors
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  addToast,
  Alert,
  Button,
  DateInput,
  TextAreaInput,
  TextInput,
} from "@octano/global-ui";

// Hooks
import { useModal } from "hooks";

// Styles
import "assets/scss/_modal_custom_size.scss";

// Store
import {
  calculateWorkingDays as doCalculateWorkingDays,
  clearCalculateWorkingDays,
  clearServerStatus,
  getHolidays,
  newVacation,
} from "reducers/ordsSlice";
import { RootState } from "store";
import dayjs from "dayjs";

export const NewVacations: React.FC = () => {
  const dispatch = useDispatch();

  const { modalClose } = useModal();
  const { control, handleSubmit, watch, setValue } = useForm<{
    date_ini: Date | null;
    date_fin: Date | null;
    motive: string;
  }>({
    defaultValues: { date_ini: null, date_fin: null, motive: "" },
  });

  const {
    loading: isLoading,
    success,
    calculateWorkingDays,
  } = useSelector((state: RootState) => state.ordsReducer);
  const onSubmit = handleSubmit(async (data) => {
    if (!data.date_ini || !data.date_fin) {
      return;
    }
    dispatch(
      newVacation({
        motive: data.motive,
        date_ini: dayjs(data.date_ini).format("YYYY-MM-DD"),
        date_fin: dayjs(data.date_fin).format("YYYY-MM-DD"),
        onSuccess() {
          dispatch(getHolidays());
        },
      })
    );
  });

  const [date_ini, date_fin] = watch(["date_ini", "date_fin"]);

  useEffect(() => {
    setValue("date_fin", null);
  }, [date_ini, setValue]);

  useEffect(() => {
    if (
      date_ini instanceof Date &&
      date_fin instanceof Date &&
      date_ini <= date_fin
    ) {
      dispatch(
        doCalculateWorkingDays({
          date_ini: dayjs(date_ini).format("YYYY-MM-DD"),
          date_fin: dayjs(date_fin).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(clearCalculateWorkingDays());
    }
  }, [date_ini, date_fin, dispatch]);

  useEffect(() => {
    if (success) {
      addToast({
        text: "Solicitud de vacaciones creada",
        autoClose: 5000,
        icon: "success",
        color: "success",
      });
      modalClose();
      dispatch(clearServerStatus());
    }
  }, [success, modalClose, dispatch]);

  let warningText: string | undefined = undefined;
  if (dayjs().isAfter(date_ini)) {
    warningText =
      "Estás haciendo una solicitud para una fecha pasada. Debes tener una autorización escrita de tu jefatura para poder hacer esto.";
  }

  return (
    <div className="px-1">
      <h4 className="py-3 fs-22 text-dark text-center">
        Nueva solicitud de vacaciones
      </h4>
      <span className="modal-subtitle">
        La solicitud será enviada para su posterior aprobación.
      </span>
      <div>
        <form onSubmit={onSubmit} className="container">
          <div className="row p-0 input-gap">
            <h5 className="col-12 fs-16 p-0">Periodo</h5>
            <div className="col-12 col-sm p-0 mr-2">
              <DateInput
                label="FECHA INICIO"
                name="date_ini"
                control={control}
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
            <div className="col-12 col-sm p-0 ml-2">
              <DateInput
                label="FECHA TERMINO"
                name="date_fin"
                control={control}
                rules={{
                  required: "Este campo es requerido",
                }}
                minDate={
                  date_ini instanceof Date
                    ? dayjs(date_ini).toDate()
                    : undefined
                }
                disabled={!date_ini}
              />
            </div>
          </div>
          <div className="row">
            <div className="col p-0">
              <TextInput
                label="Días Hábiles"
                name="workingDays"
                value={
                  calculateWorkingDays.loading
                    ? "..."
                    : calculateWorkingDays.workingDays
                }
                disabled
                errorText={calculateWorkingDays.message}
              />
            </div>
          </div>
          <div className="row">
            <div className="col p-0">
              <TextAreaInput
                label="Motivo/Descripción"
                name="motive"
                control={control}
                rules={{ required: "Este campo es requerido" }}
              />
            </div>
          </div>
          {warningText ? (
            <div className="row mt-4">
              <div className="col p-0">
                <Alert
                  className=""
                  color="info"
                  icon="information"
                  size="lg"
                  text={warningText}
                />
              </div>
            </div>
          ) : null}
        </form>
      </div>
      <div className="modal-action">
        <Button
          text="Cancelar"
          outlined
          size="lg"
          fullwidth
          className="modal-action-btn"
          onClick={() => modalClose()}
        />
        <Button
          text="Enviar SOLICITUD"
          size="lg"
          fullwidth
          loading={isLoading}
          onClick={() => onSubmit()}
          className="modal-action-btn"
          disabled={calculateWorkingDays?.code !== "0"}
        />
      </div>
    </div>
  );
};
