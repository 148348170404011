// Vendors
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Icon, Button } from '@octano/global-ui'

// Components
import { UniversityLogo } from 'components/UniversityLogo'

// Store
import { recoverFinish } from 'reducers/authSlice'

export const SuccessRecover: React.FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()

  const finishChangePass = useCallback(() => {
    dispatch(recoverFinish())
    push('/iniciar-sesion')
  }, [dispatch, push])

  return (
    <section id="formulario-login" className="pt-3 pb-4">
      <div className="d-grid gap-3">
        <div className="mt-4">
          <UniversityLogo classLogo="recover_logo" />
          <i
            className="d-block border-bottom border-primary w-100 mb-3 mt-5"
            style={{ opacity: 0.44 }}
          />
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center text-center">
        <Icon name="success" size="46px" className="mb-4 mx-auto" />
        <h2 className="fs-22 text-dark mb-3">
          Contraseña reestablecida con éxito
        </h2>
        <h3
          className="fs-18 text-light"
          style={{ maxWidth: '225px', margin: '15px auto 23px' }}
        >
          Recuperaste tu contraseña, ahora puedes iniciar sesión.
        </h3>

        <Button
          text="INICIAR SESIÓN"
          fullwidth
          className="mx-auto"
          style={{ maxWidth: '330px' }}
          onClick={finishChangePass}
        />
      </div>
    </section>
  )
}
