//Vendors
import { Icon } from '@octano/global-ui'

interface iGroupTabsSlideControl {
  nextOnclick: () => void
  previusOnclick: () => void
  isInitialTab: boolean
}

export const GroupTabsSlideControl: React.FC<iGroupTabsSlideControl> = ({
  nextOnclick,
  previusOnclick,
  isInitialTab,
}) => (
  <>
    {!isInitialTab && (
      <div className="tab-slide-control-left" onClick={() => previusOnclick()}>
        <Icon
          name="chevron_left"
          className="tab-slide-control-left"
          color="inherit"
        />
      </div>
    )}

    <div className="tab-slide-control-right" onClick={() => nextOnclick()}>
      <Icon
        name="chevron_right"
        className="tab-slide-control-right"
        color="inherit"
      />
    </div>
  </>
)
