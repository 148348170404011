// @Vendors
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

// Components
import { Global300Logo } from "components/Global300Logo";
import { PrivateLayoutHeader } from "./parts/PrivateLayoutHeader";

// Store
import { RootState } from "store";
import {
  CategoryWithPermissions,
  LinkWithPermissions,
  SIDENAV_LINKS,
} from "types/sidenav";
import { isUserAuthorizedTo } from "utils/auth";
import { SidenavLayout } from "@octano/global-ui";

// Statics
const LogoUniver = "/tenant/logo_expanded.svg";
const UniversityLogoMobile = "/tenant/logo_small.svg";

const logos = {
  desktop: {
    src: LogoUniver,
    fallbackSrc: LogoUniver,
  },
  mobile: {
    src: UniversityLogoMobile,
    fallbackSrc: UniversityLogoMobile,
  },
};

export const PrivateLayout: React.FC<{ title: string }> = ({
  title = "Not title assigned",
  children,
}) => {
  const user = useSelector((state: RootState) => state.authReducer.user) || "";
  document.title = title;

  const isAuthorizedTo = useCallback(
    (requiredPermissions: string[], allPermisionsRequired?: boolean) => {
      return isUserAuthorizedTo(
        user?.permissions || [],
        requiredPermissions,
        allPermisionsRequired
      );
    },
    [user.permissions]
  );

  const links = useMemo(() => {
    const isCategory = (
      item: LinkWithPermissions | CategoryWithPermissions
    ) => {
      return !!(item as CategoryWithPermissions).links;
    };

    const isAuthorizedLink = (link: LinkWithPermissions) => {
      if (!link.requiredPermissions?.length) return true;

      return isAuthorizedTo(
        link.requiredPermissions,
        link.allPermisionsRequired
      );
    };

    const getCategoryWithAuthorizedLinks = (
      category: CategoryWithPermissions
    ) => {
      const authorizedLinks = category.links.filter(isAuthorizedLink);
      return { ...category, links: authorizedLinks };
    };

    const getItemWithoutPermissionsInfo = (
      item: LinkWithPermissions | CategoryWithPermissions
    ) => {
      if (isCategory(item)) {
        const category = item as CategoryWithPermissions;
        const newLinks = category.links.map((link) => ({
          name: link.name,
          path: link.path,
        }));
        return { ...item, links: newLinks };
      } else {
        const link = item as LinkWithPermissions;
        return { name: link.name, path: link.path, icon: link.icon };
      }
    };

    const getSidenavAuthorizedLinks = () => {
      const filteredItems = SIDENAV_LINKS.reduce((list, item) => {
        if (isCategory(item)) {
          const categoryWithFilteredLinks = getCategoryWithAuthorizedLinks(
            item as CategoryWithPermissions
          );

          if (!categoryWithFilteredLinks.links.length) return list;

          return [...list, categoryWithFilteredLinks];
        }

        if (isAuthorizedLink(item as LinkWithPermissions)) {
          return [...list, item];
        }

        return list;
      }, [] as (LinkWithPermissions | CategoryWithPermissions)[]);

      return filteredItems.map(getItemWithoutPermissionsInfo);
    };

    return getSidenavAuthorizedLinks();
  }, [isAuthorizedTo]);

  return (
    <main id="root-main-private">
      <SidenavLayout links={links} logo={logos}>
        <div className="d-flex flex-column h-100">
          <PrivateLayoutHeader
            userImg={user.profile_image}
            userEmail={user.email}
            userName={user.full_name}
            title={title}
            userProfession={user.profession}
          />
          <div className="mx-3" style={{ flexGrow: 1 }}>{children}</div>
          <div className="d-flex justify-content-end pt-4 pb-3 pr-3">
            <Global300Logo />
          </div>
        </div>
      </SidenavLayout>
    </main>
  );
};
