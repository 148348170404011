// Vendors
import { useCallback } from 'react'
import { Button, addToast } from '@octano/global-ui'
import { useSelector } from 'react-redux'

// Components
import { UploadedImageInput } from './UploadedImageInput'
import { ViewPhoto, ViewPhotoProps } from './ViewPhoto'

// hooks
import { useModal } from 'hooks'

// Redux
import { RootState } from 'store'

interface UploadPhotoProps {
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
  photo: ViewPhotoProps['photo']
  setPhoto: React.Dispatch<React.SetStateAction<ViewPhotoProps['photo']>>
  savePhoto: (newImage: string) => Promise<void>
}

export const UploadPhoto: React.FC<UploadPhotoProps> = ({
  setEdit,
  photo,
  setPhoto,
  savePhoto,
}) => {
  const { modalClose } = useModal()
  const { user: { loading: isLoading } } = useSelector(
    (state: RootState) => state.authReducer
  )

  /**
   * Funcion que crea una preview de la foto subida y
   * cambia el estado del modal a edit TRUE para poder editar la foto
   * @param acceptedFiles: el archivo que fue subido
   * @returns void
   */
  const onChangePhoto = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles) {
        const file = Object.assign(acceptedFiles, {
          preview: URL.createObjectURL(acceptedFiles),
        })
        setPhoto(file.preview)
        setEdit(true)
      }
      return
    },
    [setPhoto, setEdit]
  )

  /**
   * Elimina la foto del estado
   * @returns void
   */
  const onTrash = useCallback(() => {
    setPhoto('')
  }, [setPhoto])

  /**
   * Guarda la foto que esta en el estado en el reducer y cierra el modal
   * @returns void
   */

  const handleSavePhoto = useCallback(() => {

    savePhoto(photo || '').then(() => {
      addToast({
        icon: 'success',
        text: 'Imagen cargada con éxito',
        color: 'success',
        autoClose: 5000,
      })
      modalClose()
    }).catch(() => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: 'Error al cargar la imagen',
        autoClose: 5000,
      })
    })
  }, [modalClose, photo, savePhoto])

  return (
    <div className="text-center px-1">
      <h4 className="py-3 fs-22 text-dark">Imagen datos personales</h4>
      <span className="picker-photo-modal-subtitle">
        La imagen debe ser tomada de frente sobre un fondo blanco. Y debe ser de
        300px 300px mínimo.
      </span>

      <div className="picker-photo-modal-info">
        <ViewPhoto photo={photo} />
        <div className="picker-photo-uploaded">
          <UploadedImageInput
            label=""
            name="photo"
            onChange={onChangePhoto}
            value={null}
            accept={'image/*'}
          />

          <Button
            icon="trash"
            outlined
            size="md"
            text="QUITAR IMÁGEN"
            color="danger"
            className="text-uppercase"
            onClick={onTrash}
          />
        </div>
      </div>
      <div className="modal-action ">
        <Button
          text="Cancelar"
          outlined
          size="lg"
          className="modal-action-btn"
          onClick={() => modalClose()}
        />
        <Button
          loading={isLoading}
          disabled={isLoading}
          text="Guardar"
          size="lg"
          className="modal-action-btn"
          onClick={() => handleSavePhoto()}
        />
      </div>
    </div>
  )
}
