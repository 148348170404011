// Vendors
import React, { useEffect } from 'react'
import { Button, Icon, TextInput } from '@octano/global-ui'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { RootState } from 'store'
import { fetchRecoverPass } from 'reducers/authSlice'

// Hooks
import { useModal } from 'hooks/useModal'

// Components
import { SuccessRecoverPassword } from './SuccessRecoverPassword'

export const ModalRecovery: React.FC = () => {
  const dispatch = useDispatch()
  const { modalClose, modalOpen } = useModal()

  const {
    loading: isLoading,
    serverErrors,
    success,
  } = useSelector((state: RootState) => state.authReducer.recover)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ 'email-recover': string }>()

  const onSubmit = handleSubmit((data) =>
    dispatch(fetchRecoverPass({ email: data['email-recover'] }))
  )

  useEffect(() => {
    if (success) modalOpen(<SuccessRecoverPassword />)
  }, [success, modalOpen])

  return (
    <div className="">
      <div className="modal-header">
        <Icon
          name="mailing"
          size="52px"
          className="mx-auto d-block"
          color="secondary"
        />
        <div className="text-black text-center fs-22 text-dark">
          Recupera tu contraseña
        </div>
        <span className="modal-message">Ingresa tu correo electrónico</span>
      </div>
      <div
        className="pb-4"
        style={{
          fontSize: '18px',
        }}
      >
        <form onSubmit={onSubmit} className=" d-grid gap-4">
          <TextInput
            label="Correo electrónico"
            name="email-recover"
            control={control}
            rules={{ pattern: /^\S+@\S+$/i }}
            errorText={
              errors['email-recover']?.type === 'pattern' ||
              (!isLoading && serverErrors)
                ? 'Ingresa un formato válido de correo electrónico.'
                : ''
            }
          />
          <div className="modal-action">
            <Button
              text="cancelar"
              size="lg"
              onClick={() => modalClose()}
              outlined
              className="modal-action-btn"
            />
            <Button
              loading={isLoading}
              size="lg"
              type="submit"
              text="Recuperar"
              onClick={() => onSubmit()}
              className="modal-action-btn"
            />
          </div>
        </form>
      </div>
    </div>
  )
}
