// Vendors
import { useDispatch } from 'react-redux'
import { Button } from '@octano/global-ui'

// Components
import { ModalWrapper } from 'components/wrapper/ModalWrapper'

// Hooks
import { useModal } from 'hooks'

// Store
import { recoverFinish } from 'reducers/authSlice'

export const SuccessRecoverPassword = () => {
  const dispatch = useDispatch()
  const { modalClose } = useModal()

  /**
   * Cierra el flujo de recuperación de contraseña, y cambia la variable recover.success a false en el store
   * @returns void
   */
  const handleCloseModal = () => {
    dispatch(recoverFinish())
    modalClose()
  }

  return (
    <ModalWrapper
      title="Revisa tu correo electrónico"
      iconName="success"
      subtitle="Si tu correo está registrado en la institución, recibirás un mensaje con las instrucciones para recuperar tu contraseña."
    >
      <div className="w-100 d-flex justify-content-center">
        <Button
          text="ENTENDIDO"
          size="lg"
          onClick={handleCloseModal}
          className="recover_success_btn"
        />
      </div>
    </ModalWrapper>
  )
}
