//Vendors
import { Icon, IconProps } from '@octano/global-ui'
import { useSelector } from 'react-redux'

// store
import { RootState } from 'store'

interface ErrorTableDataProps {
  readonly iconName?: IconProps['name']
  readonly title?: string
}

export const ErrorTableData: React.FC<ErrorTableDataProps> = ({
  iconName = 'information',
  title = 'Aún no existen registros ingresados',
}) => {
  const message = useSelector(
    (state: RootState) => state.ordsReducer.errorMessage
  )
  return (
    <div className="error_tabla">
      <Icon name={iconName} size="65px" color="secondary" />
      <h2 className="error_tabla_title">{message || title}</h2>
    </div>
  )
}
