import { IconProps } from '@octano/global-ui';
import { PermissionName } from '../types/auth';

import { TabInfo } from 'types/app';

export const localKey = 'access_token'
export const JWT_KEY = 'access_token'

export interface MenuOption {
  readonly icon?: IconProps['name']
  readonly exact?: boolean
  readonly name: string
  readonly path: string
  readonly pattern?: string[]
  readonly requiredPermissions?: PermissionName[];
}

export const tabsRemunerations: TabInfo[] = [
  {
    label: 'Liquidaciones',
    path: '/liquidaciones',
    component: 'Liquidations',
    requiredPermissions: [PermissionName.recoverShowLiquidationsTab]
  },
  {
    label: 'Asignaciones y Haberes',
    path: '/asignaciones-haberes',
    component: 'AssignmentAssets',
    requiredPermissions: [PermissionName.recoverShowAssignmentAssetsTab]
  },
  {
    label: 'Descuentos',
    path: '/descuentos',
    component: 'Discounts',
    requiredPermissions: [PermissionName.recoverShowDiscountsTab]
  },
]

export const tabsContratos: TabInfo[] = [
  {
    label: 'General',
    path: '/contratos/detalles-contrato',
    exact: false,
    component: 'General',
    requiredPermissions: [PermissionName.contractsShowDetailesContractTab]
  },
  {
    label: 'Dependencia',
    path: '/contratos/detalles-contrato/dependencia',
    exact: false,
    component: 'Dependence',
    requiredPermissions: [PermissionName.contractsShowDetailesContractDependencyTab]
  },
  {
    label: 'Estructura de sueldo',
    path: '/contratos/detalles-contrato/estructura-sueldo',
    exact: false,
    component: 'SalaryStructure',
    requiredPermissions: [PermissionName.contractsShowDetailesContractSalaryTab]
  },
]

export const tabsPermitsVacations: TabInfo[] = [
  {
    label: 'Permisos', path: '/permisos', component: 'Permits',
    requiredPermissions: [PermissionName.permitsVacationsShowPermitsTab]
  },
  {
    label: 'Vacaciones', path: '/vacaciones', component: 'Holidays',
    requiredPermissions: [PermissionName.permitsVacationsShowVacationsTab]
  },
]

export const PDFName = 'Remuneracion-Global-3000'

export const logoPdf = process.env.REACT_APP_LOGO_PDF || 'https://develop.api.global3000personnel.octano.xyz/img/universidad-frontera.png'