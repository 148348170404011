interface getCroppedImgProps {
  image: any
}

export function imageToBase64({ image }: getCroppedImgProps) {
  const canvas = document.createElement('canvas')

  const ctx = canvas.getContext('2d')
  canvas.height = image.naturalHeight
  canvas.width = image.naturalWidth
  // New lines to be added

  if (ctx !== null) {
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(image, 0, 0)
  }

  // As Base64 string
  const dataURL = canvas.toDataURL('image/png')
  return dataURL
}

type cropImgProps = {
  image: HTMLImageElement | null;
  width: number;
  height: number;
  x: number;
  y: number;
}

export function cropImageToBase64({ image, width, height, x, y }: cropImgProps) {
  const { naturalWidth, naturalHeight, width: widthImg, height: heightImg } = image as HTMLImageElement
  const canvas = document.createElement("canvas");
  const scaleX = naturalWidth / widthImg;
  const scaleY = naturalHeight / heightImg;
  canvas.width = width * scaleX;
  canvas.height = height * scaleY;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  ctx.drawImage(
    image as HTMLImageElement,
    x * scaleX,
    y * scaleY,
    width * scaleX,
    height * scaleY,
    0,
    0,
    width * scaleX,
    height * scaleY
  );
  return canvas.toDataURL('image/jpeg');
}
