import { JWT_KEY } from '../config/constants';

/**
 * Función para guardar el token del usuario
 * @param token
 */
export const setJWT = (token: string) => localStorage.setItem(JWT_KEY, token);

/**
 * Set new Item localStorage
 * @param item: any
 * @returns Item: any | false
 */
export const setItemLocal = (item: any) => {
  localStorage.setItem(JWT_KEY, JSON.stringify(item))
  const newIitem = searchItemLocal(JWT_KEY)
  if (newIitem) {
    return newIitem
  }
  return false
}

/**
 * Función para obtener el token del usuario
 */
export const getJWT = () => localStorage.getItem(JWT_KEY);

/**
 * Get Item form local storage
 * @param key: string
 * @returns Item: any | null
 */
export const searchItemLocal = (key: string) => {
  const item = localStorage.getItem(key)
  if (!item) {
    return null
  }
  return JSON.parse(item)
}

/**
 * Función para remover el token del usuario
 */
export const removeJWT = () => localStorage.removeItem(JWT_KEY);

/**
 * Remove item from localStorage
 * @param key: string
 * @returns Item: any | null
 */
export const removeItemLocal = (key: string) => {
  const searchItem = searchItemLocal(key)
  if (searchItem) {
    localStorage.removeItem(key)
    return searchItem
  }
  return null
}

/**
 * Función para obtener la data del JWT
 */
export const parseJWT = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * Función que determina si un usuario cumple con algo de los permisos requeridos. Para evaluar el cumplimiento de todos los permisos a la vez, usar allPermisionsRequired = true
 * @param userPermissions lista con los permisos que posee el usuario
 * @param permissionsToCheck lista con los permisos que se desean chequear
 * @param allPermisionsRequired En caso de ser true evaluará los requisitos como un AND y no como un OR
 * @returns boolean
 */
export const isUserAuthorizedTo = (
  userPermissions: string[],
  permissionsToCheck: string[],
  allPermisionsRequired?: boolean,
) => {
  if (!permissionsToCheck?.length) return true;

  let matchedCount = 0;

  permissionsToCheck.forEach((permission) => {
    const match = userPermissions.some((userP) => userP === permission);
    if (match) matchedCount++;
  });
  return allPermisionsRequired
    ? matchedCount === permissionsToCheck.length
    : matchedCount > 0;
};
