import { Icon } from "@octano/global-ui";
import { Col, Row } from "reactstrap";

export default function Loading() {
  return (
    <Row className="text-center py-4 px-3">
      <Col xs={12}>
        <Icon className="spin" name="circle" color="secondary" size="40px" />
      </Col>
      <Col xs={12} className="py-4">
        <span className="fs-20 text-medium">Cargando...</span>
      </Col>
    </Row>
  );
}
