import { Provider } from 'react-redux'
import { store } from 'store'
import { PDFRentWrapper } from './PDFRentWrapper'

export const PDFRentProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <PDFRentWrapper />
    </Provider>
  )
}
