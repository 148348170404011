// Vendors
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { TabInfo } from 'types/app'

interface useTabMatchComponentProps {
  readonly tabsRoute: TabInfo[]
  readonly initialViewComponent: string
}

export const useTabMatchComponent = ({
  tabsRoute,
  initialViewComponent,
}: useTabMatchComponentProps) => {
  const [matchComponent, setMatchComponent] =
    useState<string>(initialViewComponent)

  const location = useLocation()

  useEffect(() => {
    const tabMatchRoute = tabsRoute.find(
      ({ path }) => path === location.pathname
    )
    setMatchComponent(tabMatchRoute?.component || '')
  }, [tabsRoute, location, matchComponent])

  return { matchComponent }
}
