import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from 'reducers/authSlice'
import appReducer from 'reducers/appSlice'
import ordsReducer from 'reducers/ordsSlice'

export const store = configureStore({
  reducer: {
    authReducer,
    appReducer,
    ordsReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
