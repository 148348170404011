// Vendors
import clsx from 'clsx'
import { upperFirst } from 'utils'

enum StatusField {
  active = 'activo',
  finished = 'terminado',
}

interface IFieldStatus {
  status: keyof typeof StatusField
}

export const FieldStatus: React.FC<IFieldStatus> = ({ status }) => (
  <div className={clsx('field-status mx-auto', status)}>
    <span className="field-status-name">{upperFirst(StatusField[status])}</span>
  </div>
)
