// Vendors
import React, { lazy, Suspense } from 'react'
import { Spinner } from 'reactstrap'

// Components
import { GroupTabs } from 'components/containers/GroupTabs/GroupTabs'
import { CardLayout } from 'components/CardLayout'

// Constants
import { tabsRemunerations } from 'config/constants'

// Hooks
import { useTabMatchComponent } from 'hooks/useTabMatchComponent'

const Home: React.FC = () => {
  const { matchComponent } = useTabMatchComponent({
    tabsRoute: tabsRemunerations,
    initialViewComponent: 'Liquidations',
  })

  const RenderViewComponent = lazy(() => import('./parts/' + matchComponent))

  return (
    <section id="remuneraciones">
      <GroupTabs tabsInfo={tabsRemunerations} />
      <CardLayout>
        <div className="w-100">
          <Suspense
            fallback={
              <Spinner color="primary" className="mx-auto d-block my-4" />
            }
          >
            <RenderViewComponent />
          </Suspense>
        </div>
      </CardLayout>
    </section>
  )
}

export default Home
