// Vendors
import { useState, useEffect } from 'react'
import { Label } from 'reactstrap'
import { Button, OutlinedSelect } from '@octano/global-ui'
import { useForm } from 'react-hook-form'

//Components
import { Collapsable } from 'components/Collapsable'
import DateInputOutlined from 'components/DateInputOutlined'

// Utils
import { paramsOrdsRequest } from 'types/api'
import { isEmptyArray } from 'utils/array'
import dayjs from 'dayjs'
import { formatDateFilter } from 'config/date'
import { lastFortyYears } from 'utils/date'

export const TableFilter: React.FC<{
  handlerSubmit: (p: paramsOrdsRequest) => void
}> = ({ handlerSubmit }) => {
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const { control, handleSubmit, watch, setValue } =
    useForm<paramsOrdsRequest>()

  const watchFields = watch(['fromDate', 'toDate', 'year', 'status'])

  const onSubmit = handleSubmit((data) => {
    const params: paramsOrdsRequest = {
      ...data,
      toDate: data.toDate ? dayjs(data.toDate).format(formatDateFilter) : '',
      fromDate: data.fromDate
        ? dayjs(data.fromDate).format(formatDateFilter)
        : '',
    }

    if (handlerSubmit) handlerSubmit(params)
  })
  useEffect(() => {
    setIsDisable(isEmptyArray(watchFields))
  }, [watchFields])

  useEffect(() => {
    if (isDisable) handlerSubmit({})
  }, [isDisable, handlerSubmit])
  return (
    <div>
      <Collapsable>
        <form onSubmit={onSubmit} className="table-filter">
          <Label for="status" className="m-0">
            Estado
            <OutlinedSelect
              name="status"
              options={[
                { label: 'Activo', value: 'A' },
                { label: 'Terminado', value: 'I' },
              ]}
              onChange={(option: any) => {
                setValue('status', option?.value)
              }}
            />
          </Label>
          <Label for="year" className="m-0">
            Año
            <OutlinedSelect
              name="year"
              onChange={(option: any) => {
                setValue('year', option?.value)
              }}
              options={lastFortyYears().map((e) => ({
                label: `${e}`,
                value: e,
              }))}
            />
          </Label>

          <Label for="from" className="m-0">
            Periodo de contratos
            <DateInputOutlined
              label="Desde"
              name="fromDate"
              control={control}
            />
          </Label>
          <div className="table-filter-actions">
            <DateInputOutlined label="Hasta" name="toDate" control={control} />

            <Button
              disabled={isDisable}
              type="submit"
              text="buscar"
              size="sm"
              className="table-filter-btn"
            />
          </div>
        </form>
      </Collapsable>
    </div>
  )
}
