import { ReactNode } from "react";

function getColor(color: "success" | "warning" | "error") {
  if (color === "success") {
    return "#CEF0DA";
  } else if (color === "warning") {
    return "#FFE6A1";
  } else if (color === "error") {
    return "#F2C0BD";
  } else {
    throwGetColor(color);
  }
}

function throwGetColor(color: never) {
  throw new Error(`Color no implementado ${color}`);
}

export interface BadgeProps {
  color: "success" | "warning" | "error";
  children: ReactNode;
  width?: string | number;
}

export default function Badge({ color, children, width = "80px" }: BadgeProps) {
  return (
    <span
      style={{
        width,
        padding: "7px",
        borderRadius: "15px",
        color: "$black",
        opacity: "1",
        fontWeight: "normal",
        fontSize: "14px",
        backgroundColor: getColor(color),
      }}
    >
      {children}
    </span>
  );
}
