// Vendors
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Components
import { PickerPhoto } from './parts/PickerPhoto'
import { CardLayout } from 'components/CardLayout'
import { WrapperLoads } from './parts/WrapperLoads'
import { TableLoads } from './parts/TableLoads'
import {
  GenerateInfoForm,
  InputInfo,
} from 'components/containers/GenerateInfoForm'

// Store
import { getCurrentUserInfo } from 'reducers/authSlice'

const inputsPersonal: InputInfo[] = [
  {
    type: 'text',
    label: 'NOMBRES',
    columnName: 'nombre_pila',
  },
  {
    type: 'text',
    label: 'APELLIDO PATERNO',
    columnName: 'apellido_paterno',
  },
  {
    type: 'text',
    label: 'APELLIDO MATERNO',
    columnName: 'apellido_materno',
  },
  {
    type: 'text',
    label: 'RUT',
    columnName: 'rut',
  },
  {
    type: 'text',
    label: 'SEXO CI',
    columnName: 'sexo',
  },
  {
    type: 'text',
    label: 'Edad',
    columnName: 'edad',
  },
  {
    type: 'text',
    label: 'ESTADO CIVIL',
    columnName: 'estado_civil',
  },
  {
    type: 'text',
    label: 'NACIONALIDAD',
    columnName: 'nacionalidad',
  },
  {
    type: 'text',
    label: 'CARGAS',
    columnName: 'nro_cargas',
    wrapper: ({ children }) => {
      return (
        <WrapperLoads children={children} renderModal={() => <TableLoads />} />
      )
    }
  },
]

const dateContact: InputInfo[] = [
  {
    type: 'text',
    label: 'EMAIL',
    columnName: 'e_mail',
  },
  {
    type: 'text',
    label: 'Teléfono',
    columnName: 'telefono',
  },
]

const dateDomicilio: InputInfo[] = [
  {
    type: 'text',
    label: 'DIRECCIÓN',
    columnName: 'direccion'
  },
  {
    type: 'text',
    label: 'Comuna',
    columnName: 'comuna'
  },
]

const dateEducacional: InputInfo[] = [
  {
    type: 'text',
    label: 'PROFESIÓN REGISTRADA',
    columnName: 'profesion',
  },
  {
    type: 'text',
    label: 'Nivel educacional',
    columnName: 'nivel_estudios',
  },
]

const dateProvicional: InputInfo[] = [
  {
    type: 'text',
    label: 'ISAPRE/SALUD',
    columnName: 'isapre'
  },
  {
    type: 'text',
    label: 'AFP/PREVISIÓN',
    columnName: 'afp'
  },
  {
    type: 'text',
    label: 'CAJA/CCAF',
    columnName: 'caja_compensacion',
  },
]

const dateBank: InputInfo[] = [
  {
    type: 'text',
    label: 'BANCO',
    columnName: 'banco'
  },
  {
    type: 'text',
    label: 'Tipo de cuenta',
    columnName: 'tipo_cuenta'
  },
  {
    type: 'text',
    label: 'Nº DE CUENTA',
    columnName: 'cuenta_banco',
  },
]

const PersonalDate: React.FC = () => {
  const [data, setData] = useState<InputInfo[] | undefined>()
  const dispatch = useDispatch()

  const getData = useCallback(async () => {
    const data: any = await dispatch(getCurrentUserInfo())
    if (data?.payload?.data.length) {
      setData(data?.payload?.data[0] || undefined)
    }
  }, [dispatch])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <section id="datos-personales">
      <CardLayout>
        <div>
          <h2 className="generate-info-form-title fs-25">
            INFORMACIÓN PERSONAL
          </h2>
          <div className="date-personal-info">
            <PickerPhoto />
            <div className="mt-n4">
              <GenerateInfoForm title="" columns={inputsPersonal} data={data} />
            </div>
          </div>
          <div className="date-personal-aditional-info">
            <GenerateInfoForm
              title="Datos de contacto"
              columns={dateContact}
              data={data}
              col="col1"
            />
            <GenerateInfoForm
              title="DOMICILIO"
              columns={dateDomicilio}
              data={data}
              col="col1"
            />
            <GenerateInfoForm
              title="DATOS EDUCACIONALES"
              columns={dateEducacional}
              data={data}
              col="col1"
            />
          </div>

          <div className="row">
            <div className="col-sm">
              <GenerateInfoForm
                title="DATOS PREVISIONALES"
                columns={dateProvicional}
                data={data}
                col="col1"
              />
            </div>
            <div className="col-sm">
              <GenerateInfoForm
                title="DATOS BANCARIOS"
                columns={dateBank}
                data={data}
                col="col1"
              />
            </div>
          </div>
        </div>
      </CardLayout>
    </section>
  )
}

export default PersonalDate
