// Vendors
import { Table } from '@octano/global-ui'
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes'

// Hooks
import { useTableData } from 'hooks/useTableData'

//Components
import { CellFormat } from 'components/containers/CellFormat'
import { Suspense, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Spinner } from 'reactstrap'
import { getFamilyRelationshipInfo } from 'reducers/authSlice'

const columnsDesktop: ColumnTable[] = [
  {
    columnName: 'rut',
    headerText: <CellFormat>Rut</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'name',
    headerText: <CellFormat>Nombre</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'parentesco',
    headerText: <CellFormat>Parentesco</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'sexo',
    headerText: <CellFormat>Sexo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'birthday',
    headerText: <CellFormat>Fecha de nac.</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'age',
    headerText: <CellFormat>Edad</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  }
]

const columnsMobile: ColumnTable[] = [
  {
    columnName: 'rut',
    headerText: <CellFormat>Rut</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: 'name',
    headerText: <CellFormat>Nombre</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
]

export const TableLoads: React.FC = () => {
  const { family: { relationship } } = useSelector(
    (state: RootState) => state.authReducer
  )

  const dispatch = useDispatch()
  const handlerGetFamilyData = useCallback(async () => {
    dispatch(
      getFamilyRelationshipInfo()
    )
  }, [dispatch])

  useEffect(() => {
    handlerGetFamilyData()
  }, [handlerGetFamilyData])

  const { columns } = useTableData({ columnsMobile, columnsDesktop })
  return (
    <Suspense
      fallback={
        <Spinner color="primary" className="mx-auto d-block my-4" />
      }
    >
      <div className="d-grid gap-4">
        <h2 className="py-3 fs-22 text-dark text-center">Detalles de cargas</h2>
        <Table columns={columns} data={relationship ?? []} />
      </div>
    </Suspense>
  )
}
