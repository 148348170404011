//Card que se desplega con informacion del usuario autenticado

//Vendors
import clsx from 'clsx'
import { useDispatch } from 'react-redux'

// Store
import { fetchAuthLogout } from 'reducers/authSlice'

//Components
import { DropBtn } from './DropBtn'

//Hooks
import { useModal } from 'hooks'

//Components
import { ChangePassword } from './ChangePassword'

interface DropDownUserProps {
  readonly open: boolean
  readonly isOpen: React.Dispatch<React.SetStateAction<boolean>>
  readonly img: string
  readonly name: string
  readonly email: string
  readonly profession: string
}

export const DropDownUser: React.FC<DropDownUserProps> = ({
  open,
  isOpen,
  img,
  name,
  email,
  profession,
}) => {
  const { modalOpen } = useModal()
  const dispatch = useDispatch()
  return (
    <div
      className={clsx('drop-down', {
        'drop-down-open': open,
      })}
    >
      {/* El overlay permite al usuario que si pisa en 
      cualquier lugar que no sea el body del drop-down se cierre */}

      <div className="drop-down-overlay" onClick={() => isOpen(false)} />

      <div className="drop-down-body">
        <div className="drop-down-user">
          <figure className="drop-down-user-photo">
            <img src={img} alt={`foto de perfil del usario ${name}`} />
          </figure>

          <div className="drop-down-user-info">
            <strong className="drop-down-user-name">{name}</strong>

            <span>{email}</span>
            <span>{profession}</span>
          </div>
        </div>

        <div className="drop-down-action">
          <DropBtn
            text="Cambiar contraseña"
            iconName="padlock"
            onClick={() => modalOpen(<ChangePassword />)}
          />
          <DropBtn
            text="Cerrar sesión"
            iconName="log_out"
            onClick={() => dispatch(fetchAuthLogout())}
          />
        </div>
      </div>
    </div>
  )
}
