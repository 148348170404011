// Vendors
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// Utils
import { localKey } from "config/constants";
import { PrivateLayout } from "layouts/PrivateLayout/PrivateLayout";
import { PublicLayout } from "layouts/PublicLayout";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { LinkWithPermissionsRouter, typeRoutes } from "types/route";
import { searchItemLocal } from "utils";
import { isUserAuthorizedTo } from "utils/auth";

interface IRoutesRootProps {
  readonly listRoutes: LinkWithPermissionsRouter[];
  readonly views: any;
}

const RoutesContainer: React.FC<IRoutesRootProps> = ({ listRoutes, views }) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.authReducer
  );

  // Permissions user
  const isAuthorizedTo = React.useCallback(
    (requiredPermissions: string[], allPermisionsRequired?: boolean) => {
      return isUserAuthorizedTo(
        user?.permissions || [],
        requiredPermissions,
        allPermisionsRequired
      );
    },
    [user.permissions]
  );
  // Permissions routers
  const isAuthorizedLink = React.useCallback(
    (link: LinkWithPermissionsRouter) => {
      if (!link.requiredPermissions?.length) return true;
      return isAuthorizedTo(
        link.requiredPermissions,
        link.allPermisionsRequired
      );
    },
    [isAuthorizedTo]
  );

  return (
    <Switch>
      {listRoutes.map((route: LinkWithPermissionsRouter, key: number) => {
        return (
          <Route
            key={key}
            exact
            path={route.path}
            render={(routeProps) => {
              const Component = views[route.component];
              const isPrivate = route.type === typeRoutes.private;
              const isAuthenticatedUser =
                searchItemLocal(localKey) || isAuthenticated;
              const isAuthorized = isAuthorizedLink(route);
              if (isAuthenticatedUser) {
                if (isAuthorized && isPrivate) {
                  return (
                    <PrivateLayout
                      title={route.label}
                      children={<Component {...routeProps} />}
                    />
                  );
                } else {
                  return <Redirect to="/" />;
                }
              } else if (!isPrivate) {
                return (
                  <PublicLayout
                    title={route.label}
                    children={<Component {...routeProps} />}
                  />
                );
              } else {
                return <Redirect to="/iniciar-sesion" />;
              }
            }}
          />
        );
      })}
      <Redirect from="*" to={'/liquidaciones'} />
    </Switch>
  );
};

export default React.memo(RoutesContainer);
