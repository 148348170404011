//  Vendors
import { View, StyleSheet, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  firm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 292,
    borderTop: '1px solid #000000',
    paddingTop: '13.5px',
  },
})

export const Firm: React.FC<{ title: string }> = ({ title }) => (
  <View>
    <View style={styles.firm}>
      <Text>{title}</Text>
    </View>
  </View>
)
