// Vendors
import { Spinner } from 'reactstrap'
import { memo } from 'react'
import { Icon } from '@octano/global-ui'
import { usePDF } from '@react-pdf/renderer'

// Components
import { TooltipApp } from 'components/containers/Tooltip/Tooltip'

// Context

interface DownloadPdfProps {
  nameFile?: string
  target: string
  Pdf?: any
}

const Download: React.FC<DownloadPdfProps> = ({
  target = '',
  Pdf,
  nameFile = 'Certificados - Global 3000',
}) => {
  const [instance] = usePDF({ document: <Pdf /> })

  return (
    <>
      {instance.url ? (
        <a
          href={`${instance.url || '#'}`}
          target="_blank"
          download={nameFile}
          rel="noopener noreferrer"
        >
          <TooltipApp text="Descargar PDF" positioned="bottom" target={target}>
            <Icon name="file_pdf" size="22px" />
          </TooltipApp>
        </a>
      ) : (
        <Spinner color="primary" />
      )}
    </>
  )
}

export const DownloadCertificate = memo(Download)
