import { Provider } from 'react-redux'
import { store } from 'store'
import { PDFLaborOldWrapper } from './PDFLaborOldWrapper'

export const PDFLaborOldProvider: React.FC = () => {
  return (
    <Provider store={store}>
      <PDFLaborOldWrapper />
    </Provider>
  )
}
