// Vendors
import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Component
import { UploadPhoto } from './UploadPhoto'
import { PhotoEdit } from './PhotoEdit'

//Components
import { ViewPhotoProps } from './ViewPhoto'

// Redux
import { RootState } from 'store'
import { updatePhoto } from 'reducers/authSlice'

export const PickerPhotoModal: React.FC = () => {
  //El siguente estado es para saber si la foto ya fue
  //subida y se debe mostrar la vista de editarla
  const [editPhoto, setEditPhoto] = useState<boolean>(false)

  const dispatch = useDispatch()

  //Consulto del reducer la foto del usario
  const userPhoto = useSelector(
    (state: RootState) => state.authReducer.user?.profile_image
  )

  //Este estado es para almacenar la foto subida
  //y usarla durante el proceso de recorte
  const [uploadedPhoto, setUploadedPhoto] =
    useState<ViewPhotoProps['photo']>(userPhoto)

  //savePhotoUploaded es para cuando el usario ya confirmo
  //el recorte puede guardar esta foto en el rudecer
  const savePhotoUploaded = useCallback(
    async (newImage: string) => {
      await dispatch(updatePhoto({ profile_image: newImage }))
    },
    [dispatch]
  )

  return (
    <>
      {!editPhoto ? (
        <UploadPhoto
          photo={uploadedPhoto}
          setPhoto={setUploadedPhoto}
          savePhoto={savePhotoUploaded}
          setEdit={setEditPhoto}
        />
      ) : (
        <PhotoEdit
          photo={uploadedPhoto}
          setEdit={setEditPhoto}
          setPhoto={setUploadedPhoto}
        />
      )}
    </>
  )
}
