/**
 * Funcion que determina si todos los campo de un array estan vacios
 * @param array
 * @returns boolean
 */

export const isEmptyArray = (array: any[]) => {
  //contador de valores vacios
  let undefineds = 0

  array.forEach((currentField) => {
    if (currentField === undefined || currentField === null) {
      undefineds++
    }
  })

  //Aquí determino si el valor del contador de campos vacios tiene
  //el mismo valor que la longitud del array es porque todos estan vacios
  if (undefineds === array.length) {
    return true
  } else {
    return false
  }
}
