// Vendors
import { useState } from 'react'
import { Tooltip } from 'reactstrap'

interface TooltipAppProps {
  text: string
  positioned?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start'
  target: string
  className?: string
}

export const TooltipApp: React.FC<TooltipAppProps> = ({
  children,
  text = '',
  positioned = 'auto',
  target = '',
  className = '',
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <div className={`tooltip-app ${className}`}>
      <span id={target}>{children}</span>
      <Tooltip
        placement={positioned}
        isOpen={tooltipOpen}
        autohide={false}
        target={target}
        toggle={toggle}
        className="tooltip-app-base "
      >
        {text}
      </Tooltip>
    </div>
  )
}
