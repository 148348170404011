import clsx from 'clsx'

interface CellFormatProps {
  readonly bold?: boolean
  readonly align?: 'left' | 'center' | 'right'
  readonly className?: string
}

export const CellFormat: React.FC<CellFormatProps> = ({
  children,
  bold = false,
  align = 'center',
  className = '',
}) => {
  return (
    <div
      className={clsx(`${className} text-${align} mx-auto`, {
        'fw-700': bold,
        'fw-400': !bold,
      })}
    >
      {children}
    </div>
  )
}
