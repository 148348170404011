// Vendors
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer'

// PDF Parts
import { PDFLaborOld } from './PDFLaborOld'

//Assets
import { SourceSansProBold, SourceSansProRegular } from 'assets/font'

Font.register({
    family: 'Source Sans',
    fonts: [
        {
            fontStyle: 'normal',
            fontWeight: 'normal',
            src: SourceSansProRegular,
        },
        {
            fontStyle: 'normal',
            fontWeight: 'bold',
            src: SourceSansProBold,
        },
    ],
})

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Source Sans',
        padding: '40px 50px 30px',
        flexDirection: 'column',
        backgroundColor: '#fff',
    },
})

export const PDFLaborOldWrapper: React.FC = () => {
    return (
        <Document>
            <Page size={[900, 1198]} style={styles.page}>
                <PDFLaborOld />
            </Page>
        </Document>
    )
}
