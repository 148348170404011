// Vendors
import { useEffect } from 'react'
import { addToast, Icon, TextInput } from '@octano/global-ui'
import { useForm } from 'react-hook-form'

// Components
import { TooltipApp } from 'components/containers/Tooltip/Tooltip'
import { ModalWrapper } from 'components/wrapper/ModalWrapper'

// Hooks
import { useModal } from 'hooks'

// Store
import { fetchChangePass, recoverFinish } from 'reducers/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

interface IFormInput {
  readonly new_password: string
  readonly confirm_password: string
  readonly old_password: string
}

export const ChangePassword: React.FC = () => {
  const dispatch = useDispatch()
  const { serverErrors, success } = useSelector(
    (state: RootState) => state.authReducer.recover
  )

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<IFormInput>()
  const { modalClose } = useModal()

  const onSubmit = handleSubmit((data) => handleOnSubmit(data))

  const handleOnSubmit = ({
    new_password,
    confirm_password,
    old_password,
  }: IFormInput) => {
    if (confirm_password !== new_password) {
      setError('confirm_password', {
        type: 'validate',
        message: 'Las las contraseñas no coinciden',
      })
    } else {
      clearErrors('confirm_password')
      dispatch(fetchChangePass({ new_password, old_password }))
    }
  }

  useEffect(() => {
    if (serverErrors) {
      setError('old_password', {
        type: 'validate',
        message: 'La contraseña ingresada no es la correcta',
      })
    }
  }, [serverErrors, setError])

  useEffect(() => {
    if (success) {
      addToast({
        icon: 'success',
        text: 'Contraseña cambiada con éxito',
        color: 'success',
        autoClose: 5000,
      })
      dispatch(recoverFinish())
      modalClose()
    }
  }, [success, dispatch, modalClose])

  return (
    <ModalWrapper
      title="Cambia tu contraseña"
      btnReject={{ text: 'CANCELAR', onClick: modalClose }}
      btnAction={{ text: 'CAMBIAR', onClick: onSubmit }}
    >
      <form onSubmit={onSubmit} className="mt-5 d-grid gap-4">
        <TextInput
          label="CONTRASEÑA ACTUAL"
          name="old_password"
          type="password"
          minLength={8}
          control={control}
          rules={{ required: true, minLength: 8 }}
          errorText={
            errors['old_password']?.type === 'validate'
              ? errors['old_password'].message
              : ''
          }
        />

        <div className="position-relative">
          <TooltipApp
            text="La contraseña debe contener mínimo 8 caracteres"
            positioned="bottom"
            target="password_messages"
            className="revocer_confrim_tooltip"
          >
            <Icon name="information" size="22px" color="secondary" />
          </TooltipApp>

          <TextInput
            label="CONTRASEÑA NUEVA"
            name="new_password"
            type="password"
            minLength={8}
            control={control}
            rules={{ required: true, minLength: 6 }}
          />
        </div>

        <TextInput
          label="REPITE TU CONTRASEÑA NUEVA"
          name="confirm_password"
          type="password"
          minLength={8}
          control={control}
          errorText={
            errors['confirm_password']?.type === 'validate'
              ? errors['confirm_password'].message
              : ''
          }
        />
      </form>
    </ModalWrapper>
  )
}
