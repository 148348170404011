import jwt from 'jsonwebtoken'
import {
  ActivateUser, ChangePassword, RestorePassword,
  RestorePasswordConfirm
} from './../api/requests/AuthRequest'

import { FamilyRelationship, Me, UpdatePhoto } from './../api/requests/UserRequest'

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'

import { Login, ValidateEmail } from 'api/requests/AuthRequest'
import { removeItemLocal, searchItemLocal } from 'utils'
import { setItemLocal } from 'utils/auth'
import { localKey } from './../config/constants'
import { IAction } from './../types/app'
import { Auth } from './../types/auth'

const PREFIX = 'auth'
const authAdapter = createEntityAdapter<Auth>({})

const initialState: Auth = {
  isAuthenticated: false,
  expiredAuthenticated: false,
  loaded: false,
  user: {
    email: '',
    profile_image: '',
    full_name: '',
    profession: '',
    loading: false,
    errorMessage: null,
    serverErrors: false,
    success: false,
    permissions: [],
  },
  login: {
    loading: false,
    errorMessage: null,
    serverErrors: false,
    success: false,
  },
  recover: {
    loading: false,
    errorMessage: null,
    serverErrors: false,
    success: false,
  },
  activate: {
    loading: false,
    errorMessage: null,
    serverErrors: false,
    success: false,
  },
  family: {
    loading: false,
    errorMessage: null,
    serverErrors: false,
    success: false,
    relationship: []
  }
}

// Iniciar sesión
export const fetchAuthLogin = createAsyncThunk(
  `${PREFIX}/login`,
  async (user: object) => {
    const { error, data } = await Login(user)
    if (error) {
      throw Error('Email o contraseña incorrectos.')
    }
    setItemLocal(data)
    return data
  }
)
// Validar email
export const fetchAuthValidateEmail = createAsyncThunk(
  `${PREFIX}/validate-email`,
  async (email: { email: string }) => {
    const { error } = await ValidateEmail(email)
    if (error) {
      throw Error(error.message)
    }
  }
)
// Activar usuario
export const fetchAuthAccount = createAsyncThunk(
  `${PREFIX}/activate-account`,
  async (data: { password: string; token: string }) => {
    const { error } = await ActivateUser(data)
    if (error) {
      throw Error(error.message)
    }
  }
)

// Restaurar contraseña
export const fetchRecoverPass = createAsyncThunk(
  `${PREFIX}/recover-pass`,
  async (data: { email: string }) => {
    const { error } = await RestorePassword(data)
    if (error) {
      throw Error(error.message)
    }
  }
)
// Confirmar Restaurar contraseña
export const fetchRecoverPassConfirm = createAsyncThunk(
  `${PREFIX}/recover-pass-confirm`,
  async (data: { password: string; token: string }) => {
    const { error } = await RestorePasswordConfirm(data.password, data.token)
    if (error) {
      throw Error(error.message)
    }
  }
)

// Cambiar contraseña
export const fetchChangePass = createAsyncThunk(
  `${PREFIX}/change-pass`,
  async (data: { new_password: string; old_password: string }) => {
    const { access: { token: access_token } } = searchItemLocal(localKey)

    const { error } = await ChangePassword(
      { new_password: data.new_password, old_password: data.old_password },
      access_token
    )
    if (error) {
      throw Error(error.message)
    }
  }
)

export const updatePhoto = createAsyncThunk(
  `${PREFIX}/change-photo`,

  async (data: { profile_image: string }) => {
    const { error } = await UpdatePhoto(data)
    if (error) {
      throw Error(error.message)
    }
    return data.profile_image
  }
)

export const getCurrentUserInfo = createAsyncThunk(
  `${PREFIX}/me`,

  async () => {
    const { error, data } = await Me()
    if (error) {
      throw Error(error.message)
    }
    return data
  }
)

export const getFamilyRelationshipInfo = createAsyncThunk(
  `${PREFIX}/family-relation-ship`,

  async () => {
    const { error, data } = await FamilyRelationship()
    if (error) {
      throw Error(error.message)
    }
    return data
  }
)

export const fetchAuthLogout = createAsyncThunk(`${PREFIX}/logout`, () =>
  removeItemLocal(localKey)
)

export const browserReload = createAsyncThunk(`${PREFIX}/browserReload`, () => {
  const access_app = searchItemLocal(localKey)
  const token: any = jwt.decode(access_app?.access?.token)
  if (token?.exp * 1000 < Date.now()) {
    removeItemLocal(localKey)
    throw Error(`Sesión expirada`)
  }
  return access_app
})

export const authSlice = createSlice({
  name: PREFIX,
  initialState: authAdapter.getInitialState(initialState),
  reducers: {
    setPhoto(state, action) {
      state.user.profile_image = action.payload
    },
    authTrue(state) {
      state.isAuthenticated = true
    },
    recoverFinish(state) {
      state.recover.success = false
    },
    activateFinish(state) {
      state.activate.success = false
    },
    recoverClearErrors(state) {
      state.recover.serverErrors = false
      state.recover.errorMessage = ''
    },
    expiredAuth(state) {
      state.expiredAuthenticated = true
    },
    clearExpiredAuth(state) {
      state.isAuthenticated = false
      state.expiredAuthenticated = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthLogin.pending, (state) => {
        state.login.serverErrors = false
        state.login.loading = true
      })
      .addCase(fetchAuthLogin.fulfilled, (state, action: IAction) => {
        state.login.loading = false
        state.isAuthenticated = true
        state.user.email = action?.payload?.email
        state.user.profile_image = action?.payload?.profile_image
        state.user.full_name = action?.payload?.full_name
        state.user.profession = action?.payload?.profession
        state.login.serverErrors = false
        state.user.permissions = action?.payload?.permissions

      })
      .addCase(fetchAuthLogin.rejected, (state, action: IAction) => {
        state.login.loading = false
        state.login.errorMessage = action?.error?.message
        state.login.serverErrors = true
      })
      .addCase(fetchAuthLogout.fulfilled, (state) => {
        state.login.loading = false
        state.isAuthenticated = false
        state.user = initialState.user
      })
      .addCase(browserReload.rejected, (state, action: IAction) => {
        state.loaded = true;
        state.login.loading = false
        state.isAuthenticated = false
      })
      .addCase(browserReload.fulfilled, (state, action: IAction) => {
        state.loaded = true;
        state.login.loading = false
        state.isAuthenticated = !!action.payload
        state.user.email = action.payload ? action.payload.email : ''
        state.user.profile_image = action.payload?.profile_image
        state.user.full_name = action.payload ? action.payload.full_name : ''
        state.user.profession = action.payload ? action.payload.profession : ''
        state.login.serverErrors = false
        state.user.permissions = action?.payload?.permissions ?? []
      })
      .addCase(fetchAuthValidateEmail.pending, (state, action: IAction) => {
        state.activate.success = false
        state.activate.serverErrors = false
        state.activate.loading = true
      })
      .addCase(fetchAuthValidateEmail.fulfilled, (state, action: IAction) => {
        state.activate.loading = false
        state.activate.serverErrors = false
        state.activate.success = true
      })
      .addCase(fetchAuthValidateEmail.rejected, (state, action: IAction) => {
        state.activate.loading = false
        state.activate.serverErrors = true
        state.activate.success = false
      })
      .addCase(fetchAuthAccount.pending, (state, action: IAction) => {
        state.activate.success = true
        state.activate.serverErrors = false
        state.activate.loading = true
      })
      .addCase(fetchAuthAccount.fulfilled, (state, action: IAction) => {
        state.activate.loading = false
        state.activate.serverErrors = false
      })
      .addCase(fetchAuthAccount.rejected, (state, action: IAction) => {
        state.activate.loading = false
        state.activate.serverErrors = true
        state.activate.success = false
      })
      .addCase(fetchRecoverPass.pending, (state, action: IAction) => {
        state.recover.serverErrors = false
        state.recover.loading = true
      })
      .addCase(fetchRecoverPass.fulfilled, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = false
        state.recover.success = true
      })
      .addCase(fetchRecoverPass.rejected, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = true
        state.recover.success = false
      })
      .addCase(fetchRecoverPassConfirm.pending, (state, action: IAction) => {
        state.recover.serverErrors = false
        state.recover.loading = true
      })
      .addCase(fetchRecoverPassConfirm.fulfilled, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = false
        state.recover.success = true
      })
      .addCase(fetchRecoverPassConfirm.rejected, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = true
        state.recover.success = false
      })
      .addCase(fetchChangePass.pending, (state, action: IAction) => {
        state.recover.serverErrors = false
        state.recover.loading = true
      })
      .addCase(fetchChangePass.fulfilled, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = false
        state.recover.success = true
      })
      .addCase(fetchChangePass.rejected, (state, action: IAction) => {
        state.recover.loading = false
        state.recover.serverErrors = true
        state.recover.success = false
      })
      .addCase(updatePhoto.pending, (state, action: IAction) => {
        state.user.loading = true
      })
      .addCase(updatePhoto.fulfilled, (state, action: IAction) => {
        state.user.loading = false
        state.user.profile_image = action.payload
        const access_token = searchItemLocal(localKey)
        setItemLocal({
          ...access_token,
          profile_image: action.payload,
        })
      })
      .addCase(updatePhoto.rejected, (state, action: IAction) => {
        state.user.loading = false
        state.user.serverErrors = true
        state.user.success = false
      })
      .addCase(getFamilyRelationshipInfo.pending, (state, action: IAction) => {
        state.family.loading = true
      })
      .addCase(getFamilyRelationshipInfo.fulfilled, (state, action: IAction) => {
        state.family.loading = false
        state.family.relationship = action.payload.data
      })
      .addCase(getFamilyRelationshipInfo.rejected, (state, action: IAction) => {
        state.family.loading = false
        state.family.serverErrors = true
        state.family.success = false
      })
  },
})

// Actions
export const {
  setPhoto,
  authTrue,
  activateFinish,
  recoverFinish,
  recoverClearErrors,
  expiredAuth,
  clearExpiredAuth,
} = authSlice.actions

// Reducer
export default authSlice.reducer
