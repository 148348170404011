// Vendors
import { useState, useRef } from 'react'
import { addToast, Button } from '@octano/global-ui'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'

import { ViewPhotoProps } from './ViewPhoto'

// Utils
import { cropImageToBase64, imageToBase64 } from 'utils/image'

const defaultCrop: Crop = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  unit: '%',
  aspect: 4 / 4,
}

interface PhotoEditProps {
  photo: ViewPhotoProps['photo']
  setPhoto: React.Dispatch<React.SetStateAction<ViewPhotoProps['photo']>>
  setEdit: React.Dispatch<React.SetStateAction<boolean>>
}

export const PhotoEdit: React.FC<PhotoEditProps> = ({
  setPhoto,
  photo,
  setEdit,
}) => {
  const currentImg = useRef<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop>(defaultCrop)

  const savePhoto = async (selectedImage: unknown) => {
    try {
      if (typeof selectedImage === 'string') {
        const { width, height, x, y } = crop;
        const image = currentImg.current
        let newImage = ''

        // Si no se recorto la imagen, usar la imagen sin recortar
        if (typeof window === "undefined" || !width || !height || !x || !y) {
          newImage = imageToBase64({
            image: image,
          })
        } else {
          newImage = cropImageToBase64({
            image, width, height, x, y
          })
        }

        setPhoto(newImage)
        setEdit(false)
      }
    } catch (error) {
      addToast({
        icon: 'warning',
        text: 'Lo sentimos, su imagen no pudo ser recortada',
        color: 'danger',
        autoClose: 5000,
      })
    }
  }

  return (
    <>
      <div className="text-center px-1">
        <h4 className="py-3 fs-22 text-dark">Cargar imagen</h4>
        <span className="picker-photo-modal-subtitle">
          Ajusta la imagen, recuerda que tu rostro debe ser visible y estar de
          frente.
        </span>
        <div className="">
          <ReactCrop
            src={photo || ''}
            crop={crop}
            onChange={setCrop}
            onImageLoaded={(imageLoaded) => {
              currentImg.current = imageLoaded
            }}
            className="w-100"
            imageStyle={{ maxWidth: '100%', objectFit: 'contain' }}
            minHeight={150}
            minWidth={150}
            keepSelection
          />
          <div className="form-group d-flex justify-content-center mt-3 mb-5"></div>
        </div>
        <div className="modal-action ">
          <Button
            text="Cancelar"
            outlined
            size="lg"
            className="modal-action-btn"
            onClick={() => setEdit(false)}
          />
          <Button
            text={'Guardar'}
            size="lg"
            className="modal-action-btn"
            onClick={() => savePhoto(photo)}
          />
        </div>
      </div>
    </>
  )
}
