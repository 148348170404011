// Vendors
import React from 'react'

// Statics
const LogoUniver = '/tenant/logo_expanded.svg';

interface UniversityLogoProps {
  classLogo?: string
}

export const UniversityLogo: React.FC<UniversityLogoProps> = ({
  classLogo = 'logo_uni',
}) => {
  return (
    <figure className="row justify-content-md-center">
      <img src={LogoUniver} alt="" className={`w-100 ${classLogo}`} />
    </figure>
  )
}
