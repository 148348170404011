// Vendors
import { Button } from '@octano/global-ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Components
import { ModalWrapper } from './wrapper/ModalWrapper'

//Store
import { clearExpiredAuth } from 'reducers/authSlice'

// Hooks
import { useModal } from 'hooks'
import { useEffect } from 'react'

export const ExpiredTimeAuth: React.FC = () => {
  const { modalClose } = useModal()
  const dispatch = useDispatch()
  const { push } = useHistory()
  useEffect(() => {
    return () => {
      push('/iniciar-sesion')
      dispatch(clearExpiredAuth())
      modalClose()
    }
  }, [])
  return (
    <>
      <ModalWrapper
        iconName="alert"
        title="Lo sentimos, tu sesión ha expirado"
        subtitle="El tiempo de tu sesión ha terminado. Por favor, inicia sesión de nuevo."
      />
      <Button
        size="lg"
        text="VOLVER A INICIAR SESIÓN"
        onClick={() => {
          push('/iniciar-sesion')
          dispatch(clearExpiredAuth())
          modalClose()
        }}
        className="recover_success_btn d-block mx-auto"
      />
    </>
  )
}
