// Vendors
import { useMobile } from '@octano/global-ui'
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes'
import { useTable } from './useTable'

interface useTableDataParams {
  columnsDesktop: ColumnTable[]
  columnsTable?: ColumnTable[]
  columnsMobile: ColumnTable[]
}

/**
 * Pequeño hook que retorna las columnas que se usan en la
 * vista movil o desktop de las diferentes vistas de la ap
 * @param columnsMobile: lista de datos que se veran en la vista mobiles
 * @param columnsDesktop: lista de datos que se veran en la vista dekstop
 * @returns columns datos que se veran en la pantalla acutal
 */

export const useTableData = ({
  columnsDesktop,
  columnsTable = [],
  columnsMobile,
}: useTableDataParams) => {
  const isMobile = useMobile()
  const isTable = useTable()

  let columns

  if (isMobile) {
    columns = columnsMobile
  } else if (isTable && columnsTable.length) {
    columns = columnsTable
  } else {
    columns = columnsDesktop
  }

  return { columns }
}

// let columns

// if (isTable) {
//   columns = columnsTable
// } else if (isMobile) {
//   columns = columnsMobile
// } else {
//   columns = columnsDesktop
// }
