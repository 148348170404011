import { AUTH, USER } from './../endpoints'
import { request } from './../request'

// Iniciar sesion
export const Login = async (data: any) => {
  return request(AUTH.LOGIN, {
    method: 'post',
    data,
  })
}

// Validar email
export const ValidateEmail = (data: { email: string }) => {
  return request(AUTH.VALIDATE_EMAIL, {
    authRequire: false,
    method: 'post',
    data,
  })
}

// Activar email
export const ActivateUser = (data: { password: string; token: string }) => {
  return request(USER.ACTIVATE_USER, {
    method: 'post',
    data: { password: data.password },
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

// Restaurar contraseña
export const RestorePassword = (data: { email: string }) => {
  return request(AUTH.RECOVER_PASSWORD, {
    method: 'post',
    data,
  })
}

// Confirmar Restaurar contraseña
export const RestorePasswordConfirm = (password: string, token: string) => {
  return request(USER.RECOVER_PASSWORD_CONFIRM, {
    method: 'post',
    data: { password },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Actualizar contraseña
export const ChangePassword = (
  data: { new_password: string; old_password: string },
  token: string
) => {
  return request(USER.CHANGE_PASSWORD, {
    method: 'post',
    data,
    authRequire: true,
  })
}
