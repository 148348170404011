import React from 'react'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { IAction, AppState } from './../types/app'

const PREFIX = 'APP'

const initialState: AppState = {
  modalOpen: false,
  modalContent: '',
  alert: {
    isOpen: false,
    type: '',
    messages: '',
  },
}

const appAdapter = createEntityAdapter<AppState>({})

export const appSlice = createSlice({
  name: PREFIX,
  initialState: appAdapter.getInitialState(initialState),
  reducers: {
    openModal: {
      reducer: (state, action: IAction) => {
        state.modalOpen = true
        state.modalContent = action.payload
      },
      prepare: (value: string | React.ComponentElement<any, any>) => ({
        payload: value,
      }),
    },

    openAlert(state, action: IAction) {
      state.alert = action.payload
    },

    closeAlert(state) {
      state.alert = initialState.alert
    },
  },
})

// Actions
export const { openAlert, closeAlert, openModal } = appSlice.actions

// Reducer
export default appSlice.reducer
