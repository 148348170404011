import { Icon, Table } from "@octano/global-ui";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { CellFormat } from "components/containers/CellFormat";
import { TooltipApp } from "components/containers/Tooltip/Tooltip";
import { ErrorTableData } from "components/ErrorTableData";
import dayjs from "dayjs";
import { useTableData } from "hooks";
import PermissionStateApproved from "./VacationStateApproved";
import { usePagination } from "hooks/usePagination";

const columnsDesktop: ColumnTable[] = [
  {
    columnName: "createdAt",
    headerText: <CellFormat>Fecha de Solicitud</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },

  {
    columnName: "date",
    headerText: <CellFormat>Fecha de Inicio</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "motive",
    headerText: <CellFormat>Descripción</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "requestedDays",
    headerText: <CellFormat>Días hábiles</CellFormat>,
    cellFormat: ({ value, row }) => (
      <CellFormat>{row.halfDay ? "Medio día" : value}</CellFormat>
    ),
  },

  {
    columnName: "approved",
    headerText: <CellFormat>Estado de Solicitud</CellFormat>,
    cellFormat: ({ value }) => {
      return (
        <CellFormat>
          <PermissionStateApproved approved={value} />
        </CellFormat>
      );
    },
  },
];

const columnsTable: ColumnTable[] = [
  {
    columnName: "createdAt",
    headerText: <CellFormat>Fecha de Solicitud</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "date",
    headerText: <CellFormat>Fecha de Inicio</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "motive",
    headerText: <CellFormat>Descripción</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "requestedDays",
    headerText: <CellFormat>Días hábiles</CellFormat>,
    cellFormat: ({ value, row }) => (
      <CellFormat>{row.halfDay ? "Medio día" : value}</CellFormat>
    ),
  },

  {
    columnName: "approved",
    headerText: <CellFormat>Estado de Solicitud</CellFormat>,
    cellFormat: ({ value }) => {
      return (
        <CellFormat>
          <PermissionStateApproved approved={value} />
        </CellFormat>
      );
    },
  },
];

const columnsMobile: ColumnTable[] = [
  {
    columnName: "date",
    headerText: <CellFormat>Fecha de Inicio</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "motive",
    headerText: <CellFormat>Descripción</CellFormat>,
    cellFormat: ({ value, index }) => {
      const target = `motive-cell-${index}`;
      return (
        <CellFormat>
          <TooltipApp text={value} positioned="bottom" target={target}>
            <span id={target}>
              <Icon name="information" size="22px" color="secondary" />
            </span>
          </TooltipApp>
        </CellFormat>
      );
    },
  },
  {
    columnName: "requestedDays",
    headerText: <CellFormat>Nº días hábiles</CellFormat>,
    cellFormat: ({ value, row }) => (
      <CellFormat>{row.halfDay ? "Medio día" : value}</CellFormat>
    ),
  },
  {
    columnName: "approved",
    headerText: <CellFormat>Estado</CellFormat>,
    cellFormat: ({ value }) => {
      return (
        <CellFormat>
          <PermissionStateApproved approved={value} />
        </CellFormat>
      );
    },
  },
];

export interface PermissionsHistoricTableProps {
  data: {
    createdAt: string;
    date: string;
    motive: string | null;
    requestedDays: number;
    approved: boolean | null;
  }[];
}

export default function PermissionsRequestsTable({
  data: dataProp,
}: PermissionsHistoricTableProps) {
  const { columns } = useTableData({
    columnsTable,
    columnsMobile,
    columnsDesktop,
  });

  const pagination = usePagination({ totalItems: dataProp.length, limit: 5 });

  const data = dataProp.slice(
    pagination.offset,
    pagination.offset + pagination.itemsPerPage
  );

  return (
    <Table
      columns={columns}
      noResultsText={<ErrorTableData />}
      data={data}
      striped={false}
      pagination={pagination}
    />
  );
}
