// Vendors
import { DateInput, TextInput } from '@octano/global-ui'
import clsx from 'clsx'

export interface InputInfo<T = any> {
  label: string
  value?: string
  type: 'text' | 'date'
  wrapper?: (props: T) => React.ReactNode
  columnName?: string
}

type GenerateInfoFormProps<T = any> = {
  readonly data: T | any
  readonly columns: InputInfo[]
  readonly title?: string
  readonly col?: 'col3' | 'col2' | 'col1'
}

interface iTypesInputs {
  [key: string]: (props: any) => React.ReactElement | null
}

const TypesInputs: iTypesInputs = {
  text: TextInput,
  date: DateInput,
}

export enum classesColGird {
  col3 = 'generate-info-form-col-3',
  col2 = 'generate-info-form-col-2',
  col1 = 'generate-info-form-col-1',
}

export function GenerateInfoForm<T>({
  title,
  columns,
  data,
  col = 'col3',
}: GenerateInfoFormProps<T>) {
  return (
    <div className="my-4">
      {title && <h2 className="generate-info-form-title fs-25">{title}</h2>}
      <form className={clsx('generate-info-form w-100', classesColGird[col])}>
        {columns.map(
          ({ type, wrapper: Wrapper, columnName, ...rest }: any, index) => {
            let value
            if (data) {
              if (data[columnName]) {
                value = data[columnName];
              } else if (typeof (data[columnName]) === 'number') {
                value = String(data[columnName]);
              } else {
                value = '';
              }
            }

            const RenderInput = TypesInputs[type]
            return Wrapper ? (
              <Wrapper key={index}>
                <RenderInput value={value} {...rest} disabled={true} />
              </Wrapper>
            ) : (
              <RenderInput
                value={value}
                {...rest}
                disabled={true}
                key={index}
              />
            )
          }
        )}
      </form>
    </div>
  )
}
