// Vendors
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import relativeTIme from "dayjs/plugin/relativeTime";

// Components
import { TitleSection } from 'components/pdf/TitleSection';

dayjs.extend(relativeTIme);
const styles = StyleSheet.create({
  root: {
    padding: '22px 0px',
    border: '1px solid #1C1C1C',
    borderRadius: 4,
  },
  boxMainInfo: {
    padding: '0px 45px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    gap: '22px',
    width: '100%',
  },
  border: { border: '1px solid #1C1C1C', margin: '20px 0' },
  list: {
    display: 'flex',
    gap: '22px',
  },
  titleList: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  itemList: {
    color: '#1E1E1E',
    textTransform: 'uppercase',
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 9999,
    backgroundColor: 'black',
    marginRight: 7.5,
  },
})

interface IProps {
  data: any
}

export const Body: React.FC<IProps> = ({ data }) => {
  return (
    <View style={styles.root}>
      <View style={styles.boxMainInfo}>
        <View style={styles.info}>
          <TitleSection title="razón social" styles={{ marginBottom: 22 }}>
            {data?.rent?.nombre_empresa || 'N/A'}
          </TitleSection>
          <TitleSection title="RUT" styles={{ marginBottom: 22 }}>
            {data?.user?.rut || ''}
          </TitleSection>
          <TitleSection title="fecha ingreso a la institución" styles={{ marginBottom: 22 }}>
            {dayjs(data?.workSeniority[0]?.fecha_ingreso).format('DD/MM/YYYY') || ''}
          </TitleSection>
        </View>
        <View style={styles.info}>
          <TitleSection title="rut empresa" styles={{ marginBottom: 22 }}>
            {data?.rent.rut_empresa || 'N/A'}
          </TitleSection>
          <TitleSection title="trabajador" styles={{ marginBottom: 22 }}>
            {data?.user?.trabajador || 'N/A'}
          </TitleSection>
        </View>
      </View>

      <View style={styles.border} />

      <View style={styles.boxMainInfo}>
        <View>
          <Text style={styles.titleList}>Cargo funcionario:</Text>
          {data?.workSeniority?.map((work: any) => (
            <ItemList key={`${work.desc_cargo}`} title={`${work.desc_cargo}`} />
          ))}
        </View>
        <View>
          <Text style={styles.titleList}>FECHA INICIO</Text>
          {data?.workSeniority?.map((work: any) => (
            <ItemList key={`${work.contrato_desde}`} title={`${dayjs(work.contrato_desde).format('DD/MM/YYYY')}`} />
          ))}
        </View>
        <View>
          <Text style={styles.titleList}>FECHA FIN</Text>
          {data?.workSeniority?.map((work: any) => (
            <ItemList
              key={`${work.contrato_hasta}`}
              title={
                `${dayjs(work.contrato_hasta).fromNow() === 'in 30 years' ? 'INDEFINIDO' : dayjs(work.contrato_hasta).format('DD/MM/YYYY')}`
              } />
          ))}

        </View>
      </View>
    </View>
  )
}

const ItemList = ({ title, center }: { title: string; center?: boolean }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '10px',
      textAlign: center ? 'center' : 'left',
    }}
  >
    <View style={styles.circle} />
    <Text style={styles.itemList}>{title}</Text>
  </View>
)
