// Vendors
import { iModalContext, ModalContext, sizeModal } from 'context/ModalContext'
import { useContext, useCallback } from 'react'

export const useModal = () => {
  const { isOpen, modalContent, setModal, size } = useContext(ModalContext)

  const modalClose = () =>
    setModal(({ modalContent, size }: iModalContext) => ({
      isOpen: false,
      modalContent,
      size,
    }))

  const modalOpen = useCallback(
    (modalContent: undefined | JSX.Element, size?: sizeModal['size']) => {
      setModal({ isOpen: true, modalContent, size })
    },
    [setModal]
  )

  return { isOpen, modalClose, modalContent, modalOpen, size }
}
