import { Icon } from '@octano/global-ui'

export interface ViewPhotoProps {
  photo: string | undefined
}

export const ViewPhoto: React.FC<ViewPhotoProps> = ({ photo }) => {
  return (
    <figure className="picker-photo-img">
      {photo ? (
        <img src={photo} alt="" className="picker-photo-img-preview" />
      ) : (
        <Icon
          name="admision"
          className="picker-photo-icon"
          size="42px"
          color="inherit"
        />
      )}
    </figure>
  )
}
