import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import es from "../locales/es.json";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: es,
    },
  },
  lng: 'es',
  fallbackLng: 'es',
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;