// Vendors
import { StyleSheet, Text, View } from '@react-pdf/renderer'

// Components
import { TitleSection } from 'components/pdf/TitleSection'
import { coinFormatterCLP } from 'utils/text'

const styles = StyleSheet.create({
  root: {
    padding: '22px 0px',
    border: '1px solid #1C1C1C',
    borderRadius: 4,
  },
  boxMainInfo: {
    padding: '0px 45px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  info: {
    display: 'flex',
    gap: '22px',
    width: '100%',
  },
  border: { border: '1px solid #1C1C1C', margin: '20px 0' },
  list: {
    display: 'flex',
    gap: '22px',
  },
  titleList: {
    color: '#1E1E1E',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  itemList: {
    color: '#1E1E1E',
    textTransform: 'uppercase',
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 9999,
    backgroundColor: 'black',
    marginRight: 7.5,
  },
})

interface IProps {
  data: any
}

export const Body: React.FC<IProps> = ({ data }) => {
  return (
    <View style={styles.root}>
      <View style={styles.boxMainInfo}>
        <View style={styles.info}>
          <TitleSection title="rut empresa" styles={{ marginBottom: 22 }}>
            {data?.rent.rut_empresa || 'N/A'}
          </TitleSection>
          <TitleSection title="rut" styles={{ marginBottom: 22 }}>
            {data?.user?.rut || ''}
          </TitleSection>
        </View>
        <View style={styles.info}>
          <TitleSection title="razón social" styles={{ marginBottom: 22 }}>
            {data?.rent?.nombre_empresa || 'N/A'}
          </TitleSection>
          <TitleSection title="trabajador" styles={{ marginBottom: 22 }}>
            {data?.user?.trabajador || ''}
          </TitleSection>
        </View>
      </View>

      <View style={styles.border} />

      <View style={styles.boxMainInfo}>
        <View style={styles.info}>
          <TitleSection title="renta bruta" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(data?.rent?.renta_bruta || 0)}
          </TitleSection>
          <TitleSection title="ultima liquidacion" styles={{ marginBottom: 22 }}>
            {data?.rent?.periodo || 'N/A'}
          </TitleSection>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ fontSize: '16px' }}>
                {data?.rent?.p_ademas || ''}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.info}>
          <TitleSection title="renta neta" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(data?.rent?.renta_neta || 0)}
          </TitleSection>
        </View>

        <View style={styles.info}>
          <TitleSection title="haberes no imponibles" styles={{ marginBottom: 22 }}>
            {coinFormatterCLP.format(data?.rent?.renta_exentas || 0)}
          </TitleSection>
        </View>
      </View>
    </View>
  )
}