import { Icon } from '@octano/global-ui'
import clsx from 'clsx'
import { useMemo } from 'react'

interface DaysInformationProps {
  readonly numberDays: string | number
  readonly subtitle: string
  readonly active?: boolean
  readonly information?: (props: undefined | any) => void
  readonly floor?: boolean;
}

export const DaysInformation: React.FC<DaysInformationProps> = ({
  numberDays,
  subtitle,
  active = false,
  information,
  floor = false,
}) => {

  const title = useMemo(() => {
    if (floor) {
      return typeof numberDays === 'number' ? Math.floor(numberDays) : numberDays;
    } else {
      return numberDays;
    }
  }, [floor, numberDays])
  return (
    <div
      className={clsx('days-information', {
        active: active,
      })}
    >
      <div className="days-information-container">
        {information ? (
          <i onClick={information} className="days-information-icon">
            <Icon name="information_input" color="inherit" />
          </i>
        ) : (
          ''
        )}
        <h4 className="days-information-title">{title}</h4>

        <h5 className="days-information-subtitle">{subtitle}</h5>
      </div>
    </div>
  )
}
