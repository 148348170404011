// Vendors
import dayjs from "dayjs";

// PDF Parts
import { HeadPDF } from 'components/pdf/HeadPDF'
import { Footer } from '../pdf/Footer'
import { Body } from './Body'

// Redux
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { formatDateFilter } from "config/date";

export const PDFRent: React.FC = () => {
  const certificates = useSelector(
    (state: RootState) => state.ordsReducer.certificates
  )

  return (
    <>
      <HeadPDF
        title="Certificado de renta"
        subtitle={dayjs(new Date()).format(formatDateFilter)}
        fullName={certificates.user.full_name}
      />
      <Body data={certificates} />
      <Footer />
    </>
  )
}
