import dayjs from 'dayjs'
/**
 * Funcion que devuelve los ultimos 40 años, apartir del año actual
 * @returns number[]
 */

export const lastFortyYears = (): number[] => {
  const yearsToBack = 40
  const currentYear = dayjs().get('year')
  let lastYears = [currentYear]

  for (let i = 1; i <= yearsToBack; i++) {
    lastYears[i] = currentYear - i
  }

  return lastYears
}
