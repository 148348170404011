// Vendors
import { FC, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { addToast, Button, Icon, TextInput } from '@octano/global-ui'
import { useForm } from 'react-hook-form'

// Components
import { UniversityLogo } from '../../../components/UniversityLogo'
import { TooltipApp } from 'components/containers/Tooltip/Tooltip'

// Reducer
import { fetchRecoverPassConfirm, recoverClearErrors } from 'reducers/authSlice'
import { RootState } from 'store'

interface IFormInput {
  readonly new_password: string
  readonly confirm_password: string
}

export const RecoverForm: FC = () => {
  const dispatch = useDispatch()

  const { push } = useHistory()
  const { token } = useParams<{ token: string }>()

  const { loading: isLoading, serverErrors } = useSelector(
    (state: RootState) => state.authReducer.recover
  )

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<IFormInput>()

  const onSubmit = handleSubmit((data) => handleOnSubmit(data))

  const handleOnSubmit = ({ new_password, confirm_password }: IFormInput) => {
    if (confirm_password !== new_password) {
      setError('confirm_password', {
        type: 'validate',
        message: 'Las las contraseñas no coinciden',
      })
    } else {
      clearErrors('confirm_password')
      dispatch(fetchRecoverPassConfirm({ password: new_password, token }))
    }
  }

  useEffect(() => {
    if (serverErrors) {
      addToast({
        icon: 'error',
        text: 'Tiempo agotado, porfavor intentelo de nuevo',
        color: 'danger',
        autoClose: 5000,
      })
      push('/iniciar-sesion')
      dispatch(recoverClearErrors())
    }
  }, [serverErrors, push, dispatch])

  return (
    <section id="formulario-login" className="pt-3 pb-4">
      <div className="d-grid gap-3">
        <div className="mt-4">
          <UniversityLogo classLogo="recover_logo" />
          <i
            className="d-block border-bottom border-primary w-100 mb-3 mt-5"
            style={{ opacity: 0.44 }}
          />
        </div>
        <h2
          className="text-center fs-22 text-dark fw-700 mb-3"
          style={{ marginTop: '28px' }}
        >
          Recuperar tu contraseña
        </h2>
        <h3 className="text-center fs-18 text-gray fw-400">
          Por favor, ingresa tu nueva contraseña.
        </h3>
      </div>

      <form onSubmit={onSubmit} className="mt-5 d-grid gap-4 px-4">
        <div className="position-relative">
          <TooltipApp
            text="La contraseña debe contener mínimo 8 caracteres"
            positioned="bottom"
            target="password_messages"
            className="revocer_confrim_tooltip"
          >
            <Icon name="information" size="22px" color="secondary" />
          </TooltipApp>

          <TextInput
            label="Nueva contraseña"
            name="new_password"
            type="password"
            minLength={8}
            control={control}
            rules={{ required: true, minLength: 6 }}
          />
        </div>

        <TextInput
          label="Confirmar Contraseña"
          name="confirm_password"
          type="password"
          minLength={8}
          control={control}
          errorText={
            errors['confirm_password']?.type === 'validate'
              ? errors['confirm_password'].message
              : ''
          }
        />

        <Button
          loading={isLoading}
          text="RESTABLECER"
          size="lg"
          className="w-100 mb-3 mt-2"
          type="submit"
        />
      </form>
    </section>
  )
}
