// Vendors
import React, { useEffect } from 'react'
import { Button, Icon, TextInput } from '@octano/global-ui'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { fetchAuthValidateEmail } from 'reducers/authSlice'
import { RootState } from 'store'

// Hooks
import { useModal } from 'hooks'

// Components
import { SuccessActivateAccount } from './SuccessActiveteAccount'

export const ModalActivateAccount: React.FC = () => {
  const { modalOpen } = useModal()

  const { loading: isLoading, success } = useSelector(
    (state: RootState) => state.authReducer.activate
  )

  const dispatch = useDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ 'email-activate': string }>()

  const onSubmit = handleSubmit((data) =>
    dispatch(fetchAuthValidateEmail({ email: data['email-activate'] }))
  )

  useEffect(() => {
    if (success) modalOpen(<SuccessActivateAccount />)
  }, [success, modalOpen])
  return (
    <div className="">
      <div className="modal-header">
        <Icon
          name="mailing"
          size="52px"
          className="mx-auto d-block"
          color="secondary"
        />
        <div className="text-black text-center fs-22 text-dark">
          Activa tu cuenta
        </div>
        <span className="modal-message">Ingresa tu correo electrónico.</span>
      </div>
      <div
        className="pb-4"
        style={{
          fontSize: '18px',
        }}
      >
        <form onSubmit={onSubmit} className="d-grid gap-4">
          <TextInput
            label="Correo electrónico"
            name="email-activate"
            control={control}
            rules={{ pattern: /^\S+@\S+$/i, required: true }}
            errorText={
              errors['email-activate']?.type === 'pattern' ||
              errors['email-activate']?.type === 'required'
                ? 'Ingresa un formato válido de correo electrónico.'
                : ''
            }
          />

          <Button loading={isLoading} fullwidth type="submit" text="Activar" />
        </form>
      </div>
    </div>
  )
}
