// Vendors
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

// Components
import { ExpiredTimeAuth } from 'components/ExpiredTimeAuth'

// Hooks
import { useModal } from 'hooks'

// Store
import { RootState } from 'store'

export const AppExpiredAuth: React.FC = () => {
  const { modalOpen } = useModal()

  const expiredAuthenticated = useSelector(
    (state: RootState) => state.authReducer.expiredAuthenticated
  )

  // open modal expired authenticated
  useEffect(() => {
    if (expiredAuthenticated) modalOpen(<ExpiredTimeAuth />)
  }, [expiredAuthenticated, modalOpen]) // eslint-disable-next-line
  return <></>
}
