// Vendors
import { useEffect, useState, useMemo } from 'react'
import { FieldValues, useController } from 'react-hook-form'
import { Icon } from '@octano/global-ui'
import { FormText, Label } from 'reactstrap'
import DatePicker from 'react-date-picker'

const DateInputOutlined = <T extends FieldValues>(props: any) => {
  const { field, fieldState } = useController<T>(props)

  let controlledProps = field
  let controlledFieldState = fieldState

  const {
    label,
    errorText,
    disabled,
    minDate,
    maxDate,
    dayPlaceholder,
    monthPlaceholder,
    yearPlaceholder,
  } = props

  const { name, value, onChange } = controlledProps ? controlledProps : props

  const [expect] = useState<'string' | 'date' | undefined>(() => {
    if (typeof value === 'string') {
      return 'string'
    } else if (value instanceof Date) {
      return 'date'
    }
    return 'date'
  })

  const dateValue = useMemo(() => {
    if (value && typeof value === 'string') {
      const [year, month = 1, day = 1] = `${value}`
        .split('-')
        .map((v) => parseInt(v))
      return new Date(year, month - 1, day)
    } else if (value instanceof Date) {
      return value
    }
    return null
  }, [value])

  const [hasDate, setHasDate] = useState<null | Date | Date[]>(dateValue)
  useEffect(() => {
    // Se le agrega atributo id al primer input luego del input hidden con la fecha completa
    // para poder poner el foco en el input presionando sobre el label
    const input = document.querySelector(
      `input[name=${props.name}] ~.react-date-picker__inputGroup__input`
    )
    input?.setAttribute('id', name || '')
  }, [name, props.name])

  return (
    <div
      className={`g-date-picker-container g-date-picker-outlined-container ${
        hasDate ? 'with-value' : 'without-value'
      } `}
    >
      <DatePicker
        className="g-date-input-outlined"
        clearIcon={<Icon name="close" size="12px" />}
        calendarIcon={<Icon name="calendar" size="14px" />}
        showLeadingZeros={false}
        format="dd/MM/y"
        dayPlaceholder={dayPlaceholder}
        monthPlaceholder={monthPlaceholder}
        yearPlaceholder={yearPlaceholder}
        name={name}
        value={dateValue}
        onChange={(date: Date) => {
          setHasDate(date)
          if (onChange) {
            if (expect === 'string') {
              onChange(date?.toLocaleDateString('sv') ?? '')
            } else {
              onChange(date)
            }
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
      />
      <Label for={name} className={`g-input-label`}>
        {label}
      </Label>
      {(errorText || controlledFieldState?.error?.message) && (
        <FormText className="g-input-error" color="danger">
          {errorText || controlledFieldState?.error?.message}
        </FormText>
      )}
    </div>
  )
}

DateInputOutlined.defaultProps = {
  disabled: false,
  dayPlaceholder: 'dd',
  monthPlaceholder: 'mm',
  yearPlaceholder: 'yyyy',
}

export default DateInputOutlined
