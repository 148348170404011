// Vendors
import clsx from 'clsx'
import React from 'react'
import { Icon, useSidenav } from '@octano/global-ui'

//Components
import { DropDownUser } from './DropDownUser'

// Statics
import notFoundImg from 'assets/images/user.svg'

interface IPrivateLayoutHeader {
  readonly userImg: string
  readonly title: string
  readonly userName: string
  readonly userEmail: string
  readonly userProfession: string
}

export const PrivateLayoutHeader: React.FC<IPrivateLayoutHeader> = ({
  title,
  userName,
  userEmail,
  userImg,
  userProfession,
}) => {
  const userImgProfile = userImg ? userImg : notFoundImg

  const [dropOpen, setDropOpen] = React.useState<boolean>(false)

  const { toggle } = useSidenav();

  return (
    <>
      <header className="private_layout_header">
        <button className="private_layout_header_btn " onClick={() => toggle()}>
          <Icon name="menu" size="22px" color="inherit" />
        </button>

        <h1 className="private_layout_header_title">{title}</h1>
        <div className="position-relative">
          <figure
            className={clsx('user_perfil', { 'bg-white': dropOpen })}
            onClick={() => setDropOpen((prev) => !prev)}
          >
            <img src={userImgProfile} alt="" className="user_perfil_photo" />

            <span className="user_perfil_name">{userName || userEmail}</span>
          </figure>
          <DropDownUser
            open={dropOpen}
            isOpen={setDropOpen}
            img={userImgProfile}
            name={userName}
            email={userEmail}
            profession={userProfession}
          />
        </div>
      </header>

      <h1 className="private_layout_header_title_mobile">{title}</h1>
    </>
  )
}
