// Endpoints de authenticacion
export const AUTH = {
  LOGIN: '/auth/login',
  VALIDATE_EMAIL: '/auth/validate-email',
  RECOVER_PASSWORD: '/auth/recover-password',
}

// Endpoints de usuario
export const USER = {
  ACTIVATE_USER: '/users/activate',
  RECOVER_PASSWORD_CONFIRM: '/users/recover-password-confirm',
  CHANGE_PASSWORD: '/users/change-password',
  UPDATE_PHOTO: '/users/update-photo',
  ME: '/users/me',
  FAMILY_RELATION_SHIP: '/users/family-relation-ship'
}

// Endpoints de ORDS
export const ORDS = {
  CONTRACTS: '/contracts',
  CONTRACTS_DETAIL: (id: string) =>
    `/contracts/${id}`,
  CONTRACTS_SALARY_STRUCTURE_DETAIL: (id: string) =>
    `/contracts/${id}/salary-structure`,
  CONTRACTS_PDF: () =>
    `/contracts/export/pdf`,
  DISCOUNTS: '/discounts',
  CERTIFICATES: '/certificates',
  PAYMENTS: '/payments',
  ASSIGNATIONS: '/assignations',
  REQUESTS: '/requests',
  PERMISSIONS: '/permissions',
  HOLIDAYS: '/holidays',
  NEW_REQUESTS: '/requests/new-request',
  NEW_PERMIT: '/permissions/new-permit',
  NEW_VACATION: '/holidays/new-holiday',
  CALCULATE_WORKING_DAYS: '/holidays/calculate-working-days',
  GET_DAYS: '/holidays/get-days',
}
