// Vendors
import { useCallback } from 'react'
import { FileInputProps, Button, addToast } from '@octano/global-ui'
import Dropzone, { DropzoneOptions } from 'react-dropzone'
import { Col, FormText, Row } from 'reactstrap'

const oneMB = 1048576
const MAX_SIZE_PHOTO_MB = 2.1

export const UploadedImageInput: React.FC<FileInputProps> = (props) => {
  const {
    name,
    label,
    value,
    onChange,
    error,
    onDrop: onDropProp,
    ...dropZoneOptions
  } = props

  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>(
    (files, rejected, event) => {
      if (!rejected.length) {
        onDropProp && onDropProp(files, rejected, event)
        onChange && onChange(files.length ? files[0] : null)
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: `El tamaño de la imagen debe ser menor a ${MAX_SIZE_PHOTO_MB}MB`,
        })
      }
    },
    [onDropProp, onChange]
  )

  return (
    <div>
      <Dropzone
        {...dropZoneOptions}
        multiple={false}
        onDrop={onDrop}
        maxSize={oneMB * MAX_SIZE_PHOTO_MB}
      >
        {({ getInputProps, open }) => (
          <>
            <Row>
              <Col xs={12}>
                <Button
                  text="Cargar imagen"
                  color="primary"
                  outlined
                  size="md"
                  onClick={open}
                  icon="export"
                  fullwidth
                />
              </Col>
            </Row>
            <input name={name} {...getInputProps()} />
            {Boolean(error) && (
              <FormText className="g-input-error" color="danger">
                {error}
              </FormText>
            )}
          </>
        )}
      </Dropzone>
    </div>
  )
}
