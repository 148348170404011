// Vendors
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToast, Button, Select, TextAreaInput } from '@octano/global-ui'
import { useModal } from 'hooks'
import { useForm } from 'react-hook-form'

// Component
import { ModalWrapper } from 'components/wrapper/ModalWrapper'

// Type
import { INewRequest } from '../../../types/ords'

// Store
import { clearServerStatus, newRequest } from 'reducers/ordsSlice'
import { RootState } from 'store'

export const AddNewRequestsModal: React.FC = () => {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm<INewRequest>()
  const { loading: isLoading, success } = useSelector(
    (state: RootState) => state.ordsReducer
  )
  const { modalClose } = useModal()

  const onSubmit = handleSubmit((data) => {
    dispatch(newRequest(data))
  })

  useEffect(() => {
    if (success) {
      addToast({
        text: 'Solicitud creada!',
        autoClose: 5000,
        icon: 'success',
        color: 'success',
      })
      modalClose()
      dispatch(clearServerStatus())
    }
  }, [success, modalClose, dispatch])

  return (
    <ModalWrapper
      title="Nueva solicitud"
      subtitle=" Debes seleccionar una clase de solicitud y un mensaje descriptivo, el
      cual será enviado a tu jefatura directa para su posterior aprobación."
    >
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <div className="">
            <Select
              name="clase"
              label="clase"
              control={control}
              options={[
                {
                  label: 'Actualización de datos personales',
                  value: 'Actualización de datos personales',
                },
                {
                  label: 'Asignaciones',
                  value: 'Asignaciones',
                },
                {
                  label: 'Horas extra',
                  value: 'Horas extra',
                },
                {
                  label: 'Traslados',
                  value: 'Traslados',
                },
                {
                  label: 'Anotaciones a carpeta personal',
                  value: 'Anotaciones a carpeta personal',
                },
                {
                  label: 'Información general',
                  value: 'Información general',
                },
              ]}
            />
            <TextAreaInput
              label="Motivo/Descripción"
              name="motive"
              control={control}
            />
          </div>
        </div>
        <div className="modal-action">
          <Button
            text="Cancelar"
            outlined
            size="lg"
            className="modal-action-btn"
            onClick={() => modalClose()}
          />
          <Button
            loading={isLoading}
            text="Enviar SOLICITUD"
            size="lg"
            type="submit"
            className="modal-action-btn"
          />
        </div>
      </form>
    </ModalWrapper>
  )
}
