// Vendors
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContainer } from '@octano/global-ui'
import { fetchAuthLogin } from 'reducers/authSlice'

// Statics
import loginImg from 'assets/images/main-login.svg'
import loginCampusColor from 'assets/svg/logo.svg'
import loginCampusWhite from 'assets/svg/logo-blanco.svg'

// parts
import { LoginForm } from './parts/LoginForm'

const Login: React.FC = () => {
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    (data) => {
      dispatch(fetchAuthLogin(data))
    },
    [dispatch]
  )

  return (
    <section id="login-form" className="container">
      <AuthContainer
        brandLogoColor={<img src={loginCampusColor} alt="Global personnel" />}
        brandLogoWhite={
          <img
            src={loginCampusWhite}
            alt="Global personnel"
            className="logo-3000"
          />
        }
        loginImage={<img src={loginImg} alt="Universidad de la frontera" />}
      >
        <LoginForm onHandleSubmit={handleSubmit} />
      </AuthContainer>
    </section>
  )
}

export default React.memo(Login)
