// Vendors
import { lazy, Suspense } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Spinner } from 'reactstrap'

// Components
import { CardLayout } from 'components/CardLayout'
import { GroupTabs } from 'components/containers/GroupTabs/GroupTabs'

// hooks
import { useTranslation } from 'react-i18next'

// Constants
import { Button } from '@octano/global-ui'
import { tabsContratos } from 'config/constants'
import { useTabMatchComponent } from 'hooks/useTabMatchComponent'

const DetailesContract: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation();
  const history = useHistory();

  const tabsContratosWithId = tabsContratos.map(({ path, ...rest }) => ({
    path: `${path}/${id}`,
    ...rest,
  }))

  const { matchComponent } = useTabMatchComponent({
    tabsRoute: tabsContratosWithId,
    initialViewComponent: 'General',
  })

  const RenderViewComponent = lazy(() => import('./parts/' + matchComponent))

  return (
    <section id="datelles-contrato">
      <div className="text-left py-3">
        <Button
          icon="back"
          onClick={() =>
            history.push(
              `/contratos`
            )
          }
          outlined
          rounded
          size="sm"
          text={t(`go_back`)}
        />
      </div>
      <GroupTabs tabsInfo={tabsContratosWithId} />
      <CardLayout>
        <Suspense
          fallback={
            <Spinner color="primary" className="mx-auto d-block my-4" />
          }
        >
          <RenderViewComponent />
        </Suspense>
      </CardLayout>
    </section>
  )
}

export default DetailesContract
