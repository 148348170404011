import { Table } from "@octano/global-ui";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { CellFormat } from "components/containers/CellFormat";
import { ErrorTableData } from "components/ErrorTableData";
import dayjs from "dayjs";
import { usePagination } from "hooks/usePagination";

const columns: ColumnTable[] = [
  {
    columnName: "startDate",
    headerText: <CellFormat>Fecha de Inicio</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "endDate",
    headerText: <CellFormat>Fecha de Término</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "requestedDays",
    headerText: <CellFormat>Nº días hábiles</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
];

export interface VacationsHistoricTableProps {
  data: { startDate: string; endDate: string; requestedDays: number }[];
}

export default function VacationsHistoricTable({
  data: dataProp,
}: VacationsHistoricTableProps) {
  const pagination = usePagination({ totalItems: dataProp.length, limit: 5 });

  const data = dataProp.slice(
    pagination.offset,
    pagination.offset + pagination.itemsPerPage
  );

  return (
    <Table
      columns={columns}
      noResultsText={<ErrorTableData />}
      data={data}
      striped={false}
      pagination={pagination}
    />
  );
}
