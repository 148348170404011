import { createContext, FC, useState } from 'react'

export interface sizeModal {
  /**
   * Tamaños disponibles para el modal
   */
  size?: 'sm' | 'md' | 'lg'
}

export interface iModalContext extends sizeModal {
  isOpen: boolean
  modalContent: JSX.Element | undefined
  setModal?: any
}

const initialContext: iModalContext = {
  isOpen: false,
  modalContent: undefined,
  size: 'md',
}

export const ModalContext = createContext<iModalContext>(initialContext)

export const ModalContextProvider: FC = ({ children }) => {
  const [{ isOpen, modalContent, size }, setModal] = useState(initialContext)

  return (
    <ModalContext.Provider value={{ isOpen, modalContent, setModal, size }}>
      {children}
    </ModalContext.Provider>
  )
}
