// Vendors
import { FC } from 'react'
import ReactDOM from 'react-dom'
import { Modal } from '@octano/global-ui'

// Custom Hooks
import { useModal } from 'hooks/useModal'

export const ModalApp: FC = () => {
  const modalContainer: HTMLElement | null =
    document.getElementById('modal-root')
  const { isOpen, modalContent, modalClose, size } = useModal()

  return ReactDOM.createPortal(
    <Modal isOpen={isOpen} toggle={modalClose} size={size}>
      {modalContent}
    </Modal>,
    modalContainer || document.body
  )
}
