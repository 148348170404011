/**
 * Function convert the first chart of text to upperCase
 * @param text
 * @returns string
 */

export const upperFirst = (text: string): string =>
  text.charAt(0).toLocaleUpperCase() + text.slice(1)

export const coinFormatterCLP = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
  minimumFractionDigits: 0,
})

// //Formatear string a rut
// export const formatRut = (value: string) => {
//   if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
//     value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
//   } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
//     value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, '$1.$2.$3-$4')
//   } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
//     value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, '$1.$2.$3')
//   } else if (value.match(/^(\d)(\d{0,2})$/)) {
//     value = value.replace(/^(\d)(\d{0,2})$/, '$1.$2')
//   }
//   return value
// }
