// Vendors
import axios, { AxiosInstance } from 'axios'

const PORT = process.env.PORT || 8000
const BASE_URL = process.env.REACT_APP_API_URL || `http://localhost:${PORT}/api`

/**
 * Definición de la conexión con la API
 */

const API: AxiosInstance = axios.create({
  baseURL: BASE_URL,
})

export default API
