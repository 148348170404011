// Vendors
import React from 'react'

// Statics
import Logo from 'assets/svg/logo.svg'

export const Global300Logo: React.FC = () => {
  return (
    <figure className="global-300-logo">
      <img src={Logo} alt="" className="w-100 " />
    </figure>
  )
}
