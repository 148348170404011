// Vendors
import { useSelector } from 'react-redux'
import { Button } from '@octano/global-ui'

//Components
import { ViewPhoto } from './ViewPhoto'
import { useModal } from 'hooks/useModal'
import { PickerPhotoModal } from './PickerPhotoModal'
import { RootState } from 'store'

export const PickerPhoto: React.FC = () => {
  const { modalOpen } = useModal()
  const photo = useSelector((state: RootState) => state.authReducer.user?.profile_image)
  return (
    <div className="picker-photo">
      <ViewPhoto photo={photo} />

      <Button
        size="sm"
        text={photo ? 'Resubir foto' : 'Subir foto'}
        className="w-100"
        onClick={() => modalOpen(<PickerPhotoModal />)}
      />
    </div>
  )
}
