//Vendors
import { PaginationType } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { useCallback, useLayoutEffect, useState } from "react";

interface Pagination {
  readonly totalItems: number;
  readonly limit?: number | string;
}

interface UsePagination extends PaginationType {
  readonly offset: number;
}

export const usePagination = ({
  totalItems,
  limit = process.env.REACT_APP_LIMIT_REQUEST || 10,
}: Pagination): UsePagination => {
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const totalPages = Math.ceil(totalItems / +limit);

  const changePage = useCallback((nextPage: number) => {
    setCurrentPage(nextPage);
  }, []);

  useLayoutEffect(() => {
    setOffset((currentPage - 1) * +limit);
  }, [currentPage, limit]);

  return {
    itemsPerPage: +limit,
    totalItems,
    totalPages,
    currentPage,
    onChangePage: changePage,
    offset,
  };
};
