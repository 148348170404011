import { Icon } from '@octano/global-ui'
import { useModal } from 'hooks/useModal'

interface WrapperLoadsProps {
  renderModal: () => JSX.Element
}

export const WrapperLoads: React.FC<WrapperLoadsProps> = ({
  children,
  renderModal: RenderModal,
}) => {
  const { modalOpen } = useModal()
  return (
    <div className="position-relative">
      {children}
      <i
        className="carga-date-wrapper-icon"
        onClick={() => modalOpen(<RenderModal />, 'lg')}
      >
        <Icon name="eye" color="primary" size="20px" />
      </i>
    </div>
  )
}
