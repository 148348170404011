// Vendors
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  addToast,
  DateInput,
  Select,
  SelectOptionType,
  TextAreaInput,
  TextInput,
} from "@octano/global-ui";
import { ModalWrapper } from "components/wrapper/ModalWrapper";

//Hooks
import { useModal } from "hooks";

//Styles
import "assets/scss/_modal_custom_size.scss";

// Types
import { INewPermit } from "types/ords";

// Store
import { RootState } from "store";
import {
  clearServerStatus,
  getPermissions,
  newPermit,
} from "reducers/ordsSlice";
import dayjs from "dayjs";

export interface NewPermitProps {
  availableDays: number;
}

export const NewPermit: React.FC<NewPermitProps> = ({ availableDays }) => {
  const dispatch = useDispatch();
  const { modalClose } = useModal();
  const { control, handleSubmit, watch } = useForm<
    Omit<INewPermit, "halfDay"> & { halfDay: SelectOptionType }
  >();

  const { loading: isLoading, success } = useSelector(
    (state: RootState) => state.ordsReducer
  );

  const onSubmit = handleSubmit((data) => {
    dispatch(
      newPermit({
        date: dayjs(data.date).format("YYYY-MM-DD"),
        requestedDays: data.requestedDays,
        halfDay: Boolean(data.halfDay?.value),
        motive: data.motive,
        onSuccess() {
          dispatch(getPermissions());
        },
      })
    );
  });

  useEffect(() => {
    if (success) {
      addToast({
        text: "Nuevo permiso creado",
        autoClose: 5000,
        icon: "success",
        color: "success",
      });
      modalClose();
      dispatch(clearServerStatus());
    }
  }, [success, modalClose, dispatch]);

  const halfDayOption = watch("halfDay");

  const halfDay = halfDayOption?.value === 1;

  return (
    <ModalWrapper
      title="Nueva solicitud de permiso"
      subtitle="La solicitud será enviada para su posterior aprobación."
      btnReject={{
        text: "Cancelar",
        onClick: () => modalClose(),
        fullwidth: true,
      }}
      btnAction={{
        text: "Enviar SOLICITUD",
        onClick: () => onSubmit(),
        fullwidth: true,
        type: "submit",
        loading: isLoading,
      }}
    >
      <form onSubmit={onSubmit} className="container">
        <div className="row">
          <div className="col p-0 ">
            <Select
              name="halfDay"
              label="TIPO DE PERMISO"
              control={control}
              options={[
                {
                  label: "Día Completo",
                  value: 0,
                },
                {
                  label: "Medio Día",
                  value: 1,
                },
              ]}
              rules={{ required: true }}
              defaultValue="0"
            />
          </div>
        </div>

        <div className="row input-gap">
          <div className="col-12 p-0 ">
            <DateInput
              label="FECHA"
              name="date"
              control={control}
              rules={{ required: true }}
            />
          </div>
        </div>

        {!halfDay && (
          <div className="row input-gap">
            <div className="col-12 p-0 ">
              <TextInput
                label="Cantidad"
                name="requestedDays"
                control={control}
                shouldUnregister
                rules={{
                  required: true,
                  min: {
                    value: 1,
                    message: "El valor mínimo es 1",
                  },
                  max: {
                    value: availableDays,
                    message: `La cantidad solicitada excede el máximo disponible que es ${availableDays}`,
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "No es un número válido",
                  },
                }}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col p-0">
            <TextAreaInput
              label="Motivo/Descripción"
              name="motive"
              control={control}
            />
          </div>
        </div>
      </form>
    </ModalWrapper>
  );
};
