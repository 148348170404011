//Vendors
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '@octano/global-ui'
import { ColumnTable } from '@octano/global-ui/dist/components/Table/types/TableTypes'

// Component
import { CardLayout } from 'components/CardLayout'
import { CellFormat } from 'components/containers/CellFormat'
import { DownloadCertificate } from 'views/Certificates/parts/pdf/DownloadPdf'
import { PDFLaborOldProvider } from './parts/LaborOld/PDFLaborOldProvider'
import { PDFRentProvider } from './parts/Rent/PDFRentProvider'

// Redux
import { RootState } from 'store'
import { getCertificates } from 'reducers/ordsSlice'

const columns: ColumnTable[] = [
  {
    columnName: 'type',
    headerText: (
      <CellFormat align="left" className="pl-0 pl-sm-5">
        Tipo de certificado
      </CellFormat>
    ),
    cellFormat: (props) => (
      <CellFormat align="left" className="pl-0 pl-sm-5">
        {props.value}
      </CellFormat>
    ),
  },
  {
    columnName: 'pdf',
    headerText: '',
    cellFormat: (props) => <CellFormat align="right">{props.value}</CellFormat>,
  },
]

const data = [
  {
    type: 'Certificado de antigüedad laboral',
    pdf: (
      <DownloadCertificate
        nameFile="Certificado de antigüedad laboral"
        Pdf={PDFLaborOldProvider}
        target="pdf-id-1"
      />
    ),
  },
  {
    type: 'Certificado de renta',
    pdf: (
      <DownloadCertificate
        nameFile="Certificado de renta"
        Pdf={PDFRentProvider}
        target="pdf-id-2"
      />
    ),
  },
  // SOLO SOPORTAMOS DE ANTIGUEDAD LABORAL Y DE RENTA POR EL MOMENTO
  // {
  //   type: 'Certificado de empleo',
  //   pdf: (
  //     <DownloadCertificate
  //       nameFile="Certificado de empleo"
  //       Pdf={PDFJob}
  //       target="pdf-id-3"
  //     />
  //   ),
  // },
  // {
  //   type: 'Certificado de afiliación AFP/Caja',
  //   pdf: <DownloadPdf Pdf={PDFLaborOld} target="pdf-id-4" />,
  // },
]

const Certificates: React.FC = () => {
  const dispatch = useDispatch()
  const { loading: isLoading } = useSelector(
    (state: RootState) => state.ordsReducer
  )
  useEffect(() => {
    dispatch(getCertificates())
  }, [dispatch])

  return (
    <section id="certificados">
      <CardLayout>
        <Table
          isLoadingResults={isLoading}
          columns={columns}
          data={data}
          striped={false}
        />
      </CardLayout>
    </section>
  )
}

export default Certificates
