// Vendors
import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

//Components
import { CardLayout } from 'components/CardLayout'
import { Global300Logo } from 'components/Global300Logo'

// Parts
import { ActivateForm } from './parts/ActivateForm'
import { SuccessActivate } from './parts/SuccessActivate'

// Store
import { RootState } from 'store'

const Activate: React.FC = () => {
  const { success } = useSelector(
    (state: RootState) => state.authReducer.activate
  )
  const { token } = useParams<{ token: string }>()
  const history = useHistory()

  useEffect(() => {
    if (!token) {
      history.push('/')
    }
  }, [token, history])

  return (
    <section
      id="recover-form"
      className="px-4 min-vh-100 d-flex flex-column justify-center"
    >
      <div className="recover_confirm_card container py-3">
        <CardLayout>
          {!success ? <ActivateForm /> : <SuccessActivate />}
        </CardLayout>
      </div>
      <Global300Logo />
    </section>
  )
}

export default Activate
