// Vendors
import React from 'react'
import clsx from 'clsx'

// Constants
import { Tab } from './Tab'
import { GroupTabsSlideControl } from './GroupTabsSlideControl'

// Type
import { LinkWithPermissionsTabs, TabInfo } from 'types/app'

// Hooks
import { useGroupTabSlider } from 'hooks/useGroupTabSlider'
import { isUserAuthorizedTo } from 'utils/auth'
import { RootState } from 'store'
import { useSelector } from 'react-redux'

interface IGroupTabs<T> {
  readonly tabsInfo: T[]
  tabComponent?: (props: any) => React.ReactElement | null
  tabProps?: any
}

/**
 * Este componente reenderiza un conjuto de tabs
 * en la vista mobile se convierte en un corousel
 * los cambios de la vista a mobile se hacen en el archivo _tab.scss
 */

export const GroupTabs: React.FC<IGroupTabs<TabInfo>> = ({
  tabsInfo,
  tabComponent: TabComponent = Tab,
  tabProps,
}) => {
  const user = useSelector((state: RootState) => state.authReducer.user)
  const { nextTab, previusTab, isPreviusTabs, isCurrentTab, isInitialTab } =
    useGroupTabSlider(tabsInfo.length)
  
  // Permissions user
  const isAuthorizedTo = React.useCallback(
    (requiredPermissions: string[], allPermisionsRequired?: boolean) => {
      return isUserAuthorizedTo(
        user?.permissions || [],
        requiredPermissions,
        allPermisionsRequired,
      );
    },
    [user.permissions],
  );
  // Permissions routers 
  const isAuthorizedLink = React.useCallback((link: LinkWithPermissionsTabs) => {
    if (!link.requiredPermissions?.length) return true;
    return isAuthorizedTo(link.requiredPermissions, link.allPermisionsRequired);
  },[isAuthorizedTo]);

  return (
    <nav className="tab_container">
      {tabsInfo.length > 2 ? (
        <GroupTabsSlideControl
          previusOnclick={previusTab}
          nextOnclick={nextTab}
          isInitialTab={isInitialTab()}
        />
      ) : (
        ''
      )}
      {tabsInfo.map(({ label, path, requiredPermissions = [], exact = true }, index) => {
        return isAuthorizedLink({ label, path, requiredPermissions }) ?
         (
          <TabComponent
            label={label}
            to={path}
            key={`tab-${index}`}
            className={clsx('tab', {
              tabCurrentView: isCurrentTab(index),
              tabBeforeCurrentView: isPreviusTabs(index),
            })}
            activeClassName="tab_active"
            exact={exact}
            {...tabProps}
          />
        ): ''
      })}
    </nav>
  )
}
