import { ORDS } from './../endpoints'
import { request } from './../request'

//Types
import { paramsOrdsRequest } from 'types/api'
import { ILiquidationPDF, INewPermit, INewRequest, INewVacation } from 'types/ords'

// Contratos
export const GetContracts = async ({
  offset,
  limit,
  ...rest
}: paramsOrdsRequest) => {
  const filterParams = { ...rest }
  const requestParams = { offset, limit }
  return request(ORDS.CONTRACTS, {
    params: requestParams,
    authRequire: true,
    method: 'POST',
    data: filterParams || {},
  })
}

// Detalle de contracto por id de contrato
export const GetContractDetail = async ({
  id
}: { id: string }) => {
  return request(ORDS.CONTRACTS_DETAIL(id), {
    authRequire: true,
    method: 'GET',
  })
}

// Es
export const GetSalaryStructureDetail = async ({
  id
}: { id: string }) => {
  return request(ORDS.CONTRACTS_SALARY_STRUCTURE_DETAIL(id), {
    authRequire: true,
    method: 'GET',
  })
}


// Descuentos
export const GetDiscounts = async ({
  offset,
  limit,
  ...rest
}: paramsOrdsRequest) => {
  const filterParams = { ...rest }
  const requestParams = { offset, limit }

  return request(ORDS.DISCOUNTS, {
    params: requestParams,
    authRequire: true,
    method: 'POST',
    data: filterParams || {},
  })
}

// Certicados
export const GetCertificates = async () => {
  return request(ORDS.CERTIFICATES, {
    authRequire: true,
    method: 'GET',
  })
}

// Remuneraciones
export const GetPayments = async ({
  offset,
  limit,
  ...rest
}: paramsOrdsRequest) => {
  const filterParams = { ...rest }
  const requestParams = { offset, limit }

  return request(ORDS.PAYMENTS, {
    params: requestParams,
    authRequire: true,
    method: 'POST',
    data: filterParams || {},
  })
}

// Asignaciones
export const GetAssignations = async ({
  offset,
  limit,
  ...rest
}: paramsOrdsRequest) => {
  const filterParams = { ...rest }
  const requestParams = { offset, limit }

  return request(ORDS.ASSIGNATIONS, {
    params: requestParams,
    authRequire: true,
    method: 'POST',
    data: filterParams || {},
  })
}

// Requests
export const GetRequests = async ({ offset, limit }: paramsOrdsRequest) => {
  const requestParams = { offset, limit }

  return request(ORDS.REQUESTS, {
    params: requestParams,
    authRequire: true,
    method: 'GET',
  })
}

// PERMISSIONS
export const GetPermissions = async () => {
  return request(ORDS.PERMISSIONS, {
    authRequire: true,
    method: 'GET',
  })
}

// HOLIDAYS
export const GetHolidays = async () => {

  return request(ORDS.HOLIDAYS, {
    authRequire: true,
    method: 'GET',
  })
}

// NEW REQUESTS
export const NewRequests = async (data: INewRequest) => {
  return request(ORDS.NEW_REQUESTS, {
    authRequire: true,
    method: 'POST',
    data,
  })
}

// NEW REQUESTS
export const NewPermit = async (data: INewPermit) => {
  return request(ORDS.NEW_PERMIT, {
    authRequire: true,
    method: 'POST',
    data,
  })
}

// NEW REQUESTS
export const NewVacation = async (data: INewVacation) => {
  return request(ORDS.NEW_VACATION, {
    authRequire: true,
    method: 'POST',
    data,
  })
}

// Calculate Working Days
export const CalculateWorkingDays = async (data: {
  date_ini: Date | string;
  date_fin: Date | string;
}) => {
  return request(ORDS.CALCULATE_WORKING_DAYS, {
    authRequire: true,
    method: "POST",
    data,
  });
};

// GET DAYS
export const GetDays = async () => {
  return request(ORDS.GET_DAYS, {
    authRequire: true,
    method: 'GET',
  })
}

// PDF
export const getPdf = async (data: ILiquidationPDF) => {
  return await request(
    ORDS.CONTRACTS_PDF(),
    {
      authRequire: true,
      responseType: 'blob',
      method: 'GET',
      params: data
    },
  );
};