//Vendors
import { useState, useCallback, useEffect } from 'react'

/**
 * Este hook provee la funciones necesarias para
 * usar un carrousel de un conjuntos de componentes
 * Las constantes declaradas fuera del hook cumplen lo siguente:
 * - oneTab: representa el valor de un tab y se usa para actualizar currentTab
 * - initilTab: es el valor incial que estará activo en corusel,
 * se compara con el indice del array de tab para validar si el que se esta reendrizando
 * es el que estar activo
 * - totalTabs: es la cantidad de tabs o componentes presentes en el carousel
 */

const oneTab = 1
const initialTab = 0

export const useGroupTabSlider = (totalTabs: number) => {
  const [currentTab, setCurrentTab] = useState(initialTab)

  /**
   * Cambia el estado para reenderizar el siguente tab
   * @returns void
   */
  const nextTab = useCallback(() => {
    setCurrentTab((prev) => prev + oneTab)
  }, [setCurrentTab])

  /**
   * Cambia el estado para reenderizar el tab anterior
   * @returns void
   */
  const previusTab = useCallback(() => {
    setCurrentTab((prev) => (prev === initialTab ? prev : prev - oneTab))
  }, [setCurrentTab])

  /**
   * Funcion que valida si el tab que se esta
   * reenderizando es anterior al tab actual que se debe ver activo
   * @param currentTabRender
   * @returns boolean
   */
  const isPreviusTabs = useCallback(
    (currentTabRender) => {
      return currentTab > currentTabRender
    },
    [currentTab]
  )

  /**
   * Funcion que valida si el tab que se esta
   * reenderizando es que se debe activar
   * @param currentTabRender
   * @returns boolean
   */
  const isCurrentTab = useCallback(
    (currentTabRender) => {
      return currentTab === currentTabRender
    },
    [currentTab]
  )

  /**
   * Funcion que valida si el tab que se esta
   * reenderizando el primero de la lista
   * @param currentTabRender
   * @returns boolean
   */
  const isInitialTab = useCallback(() => {
    return currentTab === initialTab
  }, [currentTab])

  useEffect(() => {
    /**
     * Este efecto reinicia el estado al tab inicial cuando
     * ya se recorrio esta el ultimo tab
     * Se valida usando el la cantidad total de los tab
     * si el tab actual es igual a el total de de los tabs volvera al tab inicial
     */
    if (currentTab === totalTabs) {
      setCurrentTab(initialTab)
    }
  }, [currentTab, setCurrentTab, totalTabs])

  return { nextTab, previusTab, isPreviusTabs, isCurrentTab, isInitialTab }
}
