//Vendors
import { Button, ButtonProps, Icon, IconPaths } from '@octano/global-ui'

interface ModalWrapperProps {
  readonly title: string
  readonly iconName?: keyof typeof IconPaths
  readonly subtitle?: string
  readonly btnReject?: Pick<ButtonProps, 'onClick' | 'text' | 'fullwidth'>
  readonly btnAction?: Pick<
    ButtonProps,
    'onClick' | 'text' | 'fullwidth' | 'type' | 'loading'
  >
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  title,
  btnReject,
  btnAction,
  subtitle,
  iconName,
  children,
}) => {
  return (
    <div className="px-1">
      <div className="modal-header">
        {iconName && <Icon name={iconName} size="50px" color="primary"></Icon>}
        <h4 className="fs-22 text-dark text-center">{title}</h4>
        {subtitle && <span className="modal-subtitle">{subtitle}</span>}
      </div>
      <div className="mb-4">{children}</div>
      <div className="modal-action">
        {btnReject && (
          <Button
            text={btnReject.text}
            outlined
            size="lg"
            fullwidth={btnReject.fullwidth}
            className={`modal-action-btn ${
              !btnReject.fullwidth ? 'modal-action-btn-small' : ''
            }`}
            onClick={btnReject.onClick}
          />
        )}
        {btnAction && (
          <Button
            text={btnAction.text}
            size="lg"
            loading={btnAction.loading}
            onClick={btnAction.onClick}
            fullwidth={btnAction.fullwidth}
            className={`modal-action-btn ${
              !btnAction.fullwidth ? 'modal-action-btn-small' : ''
            }`}
          />
        )}
      </div>
    </div>
  )
}
