// Vendors
import { useState, useEffect } from 'react'
import { Icon, useMobile } from '@octano/global-ui'
import clsx from 'clsx'

export const Collapsable: React.FC = ({ children }) => {
  const mobile = useMobile()
  const [collapse, setCollapse] = useState<boolean>(false)

  useEffect(() => {
    setCollapse(!mobile)
  }, [mobile])

  return (
    <div className="collapsable">
      <div className="collapsable-head" onClick={() => setCollapse(!collapse)}>
        <div className="collapsable-head-layout">
          <div className="d-flex align-items-center gap-2">
            <Icon name="filter" color="secondary" size="13px" />
            <span className="collapsable-head-title">Filtros de búsquedad</span>
          </div>
          <Icon name="chevron_down" color="secondary" size="10px" />
        </div>
      </div>
      <div
        className={clsx('collapsable-content', {
          'collapsable-content-active': collapse,
        })}
      >
        {children}
      </div>
    </div>
  )
}
